import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ClientPortalModalComponent } from 'src/app/modals/client-portal-modal/client-portal-modal.component';
import { RegistrationModalComponent } from 'src/app/modals/registration-modal/registration-modal.component';
import { ClientInfo } from 'src/app/model/client/client-info.model';
import { AppConfig } from 'src/app/model/constants/app-config';
import { LajeansdataApiService } from 'src/app/service/lajeansdata-api.service';
import { LajeanshareApiService } from 'src/app/service/lajeanshare-api.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  userProfile: any;
  isAdmin = false;
  isLoggedIn = false;
  username;

  BUTTON_TOOLBAR_LOGIN = AppConfig.USER_ROUTE_ACTION_LOGIN;
  BUTTON_TOOLBAR_MYINFO = AppConfig.USER_ROUTE_ACTION_MYINFO;
  BUTTON_TOOLBAR_ADMINISTRATION = AppConfig.USER_ROUTE_ACTION_CLIENTADMINISTRATION;
  BUTTON_TOOLBAR_LOGOUT = AppConfig.USER_ROUTE_ACTION_LOGOUT;
  BUTTON_TOOLBAR_CLIENTPORTAL = AppConfig.USER_ROUTE_ACTION_CLIENTPORTAL;
  BUTTON_TOOLBAR_SUBMITREVIEW = AppConfig.ROUTE_DIRECTION_SUBMITREVIEW;

  constructor(private modalService: NgbModal,
    private loginSession: LajeanshareApiService,
    private dataService: LajeansdataApiService,
    private router: Router) {

    loginSession.itemValue.subscribe(currentUser => {
      if (currentUser != null) {
        this.userProfile = currentUser;
        this.isLoggedIn = true;
        this.username = AppConfig.TOOLBAR_USER_GREETING + this.userProfile.firstName + ' ' + this.userProfile.lastName
      }
      else {
        this.isLoggedIn = false;
      }
    });
  }

  ngOnInit(): void {
    this.isUserLoggedIn();
  }

  isUserLoggedIn() {

    let userProfile: any = this.loginSession.getUserProfileData();

    if (userProfile != null) {
      this.isLoggedIn = true;
      this.username = AppConfig.TOOLBAR_USER_GREETING + userProfile.firstName + ' ' + userProfile.lastName;
    }
    else {
      this.isLoggedIn = false;
    }
  }


  openLogin(action) {
    let userProfile: any = this.loginSession.getUserProfileData();
    let userAction = action.target.text;

    if (userProfile == null) {
      console.log(action);
      const confirmSubmissionModalRef = this.modalService.open(RegistrationModalComponent, { size: 'sm', backdrop: 'static' })
      confirmSubmissionModalRef.result.then((result) => {
        if (result != null) {
          if (result.loginStatus == AppConfig.ROUTE_DIRECTION_REGISTER && userAction == AppConfig.USER_ROUTE_ACTION_LOGIN) {
            this.router.navigate([AppConfig.ROUTE_URL_REGISTER]);
          }
          /*
          else if (result.loginStatus == AppConfig.ROUTE_DIRECTION_MYINFO && userAction == AppConfig.USER_ROUTE_ACTION_MYINFO) {
            this.router.navigate([AppConfig.ROUTE_URL_MYINFO]);
          }
          */
          else if (result.loginStatus == AppConfig.ROUTE_DIRECTION_MYINFO && userAction == AppConfig.USER_ROUTE_ACTION_LOGIN) {
            this.router.navigate([AppConfig.ROUTE_URL_HOME]);
          }
          else if (result.loginStatus == AppConfig.ROUTE_DIRECTION_SUBMITREVIEW && userAction == AppConfig.ROUTE_DIRECTION_SUBMITREVIEW) {
            this.isLoggedIn = true;
            this.router.navigate([AppConfig.ROUTE_URL_REVIEW]);
          }
    
        }
      })
    }
    else {
      this.router.navigate([AppConfig.ROUTE_URL_REVIEW]);

    }
  }

  openClientPortal() {
    const confirmSubmissionModalRef = this.modalService.open(ClientPortalModalComponent, { size: 'lg', backdrop: 'static' })
    confirmSubmissionModalRef.result.then((result) => {
      if (result != null) {
        if (result.action == AppConfig.USER_CLIENTPORTAL_ACTION_CLOSE) {
          this.router.navigate([AppConfig.ROUTE_URL_HOME]);
        }
        else if (result.action == AppConfig.USER_CLIENTPORTAL_ACTION_PROCEED) {
          window.open(AppConfig.URL_CLIENTPORTAL, "_blank");
        }
      }
    })
  }

  logout() {
    this.dataService.clearLocalStorage();
    this.isLoggedIn = false;
    this.isAdmin = false;
    this.router.navigate([AppConfig.ROUTE_URL_HOME]);
  }

}
