import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spravato',
  templateUrl: './spravato.component.html',
  styleUrls: ['./spravato.component.scss']
})
export class SpravatoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
