import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegistrationModalComponent } from 'src/app/modals/registration-modal/registration-modal.component';
import { SubmissionStatusModalComponent } from 'src/app/modals/submission-status-modal/submission-status-modal.component';
import { Users, Documents, Medications, TreatmentPlans, ExtendedClientInfo, InsuranceInfo, ClientInfo } from 'src/app/model/client/client-info.model';
import { AppConfig, IDocuments, IMedications, ITreatments } from 'src/app/model/constants/app-config';
import { LajeansdataApiService } from 'src/app/service/lajeansdata-api.service';
import { LajeanshareApiService } from 'src/app/service/lajeanshare-api.service';


let ELEMENT_DOCUMENT_DATA: IDocuments[] = [];
let ELEMENT_TREATMENT_DATA: ITreatments[] = [];
let ELEMENT_MEDICATIONS_DATA: IMedications[] = [];

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})

export class EditUserComponent implements OnInit {

  UserInfoGroup: any;
  SPINNER_MESSAGE = AppConfig.SPINNER_LOAD_PROFILE_MESSAGE;
  photoUrl;
  userName;
  documentId;
  private currentEditUserProfile;

  medicationsColumns: string[] = ['medName', 'medStartData', 'medEndDate', 'prescriber'];
  treamentsColumns: string[] = ['planName', 'planStartDate', 'planNurse'];
  documentsColumns: string[] = ['name', 'type'];

  dataSourceMed = new MatTableDataSource(ELEMENT_MEDICATIONS_DATA);
  dataSourceDocs = new MatTableDataSource(ELEMENT_DOCUMENT_DATA);
  dataSourceTreatments = new MatTableDataSource(ELEMENT_TREATMENT_DATA);

  @ViewChild(MatPaginator, { static: false }) paginatorMeds: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableMeds: MatTable<any>;
  @ViewChild(MatSort, { static: false }) sortMeds: MatSort;

  @ViewChild(MatPaginator, { static: false }) paginatorDocs: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableDocs: MatTable<any>;
  @ViewChild(MatSort, { static: false }) sortDocs: MatSort;

  @ViewChild(MatPaginator, { static: false }) paginatorTreatments: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableTreatments: MatTable<any>;
  @ViewChild(MatSort, { static: false }) sortTreatments: MatSort;

  constructor(private formbuilder: FormBuilder,
    private fireStoreService: AngularFirestore,
    public ngxShowLoader: NgxSpinnerService,
    private loginSession: LajeanshareApiService,
    private modalService: NgbModal,
    private router: Router,
    private dataService: LajeansdataApiService) { }

  ngOnInit(): void {
    this.UserInfoGroup = this.formbuilder.group({
      AuthorizationToken: [''],
      Id: [''],
      Provider: [''],
      UserName: [''],
      PhotoUrl: [''],
      UserRegistrationLastName: ['', Validators.required],
      UserRegistrationFirstName: ['', Validators.required],
      UserRegistrationMiddleName: [''],
      UserRegistrationSuffix: [''],
      UserRegistrationEmail: ['', Validators.compose([Validators.required, Validators.email])],
      UserRegistrationPhone: ['', Validators.required],
      UserRegistrationGroupId: ['', Validators.required],
      UserRegistrationPolicyNumber: ['', Validators.required],
      UserRegistrationPolicyPhoneNumber: ['', Validators.required],
      UserRegistrationSymtoms: [''],
      UserRegistrationDateOfBirth: ['', Validators.required]

    })

    this.getUserProfile();
  }

  /**
   * Getter method to allow form to retrieve controls shorthand
   */
  get registerFormControl() {
    return this.UserInfoGroup.controls;
  }

  getUserPermission()
  {
    let userProfile: any = this.loginSession.getCurrentUserProfileData();
    
    if(userProfile != null)
    {
      if(userProfile.User.accountInfo.isAdmin == true)
      {
        this.getUserProfile();
      }
      else
      {
        const confirmSubmissionModalRef = this.modalService.open(SubmissionStatusModalComponent, { size: 'lg', backdrop: 'static' })

        confirmSubmissionModalRef.componentInstance.enableHome = false;
        confirmSubmissionModalRef.componentInstance.modal_body = AppConfig.MODAL_BODY_ADMINISTRATION_ROLE_ERROR;
        confirmSubmissionModalRef.componentInstance.modal_header_title = AppConfig.MODAL_HEADER_ADMINISTRATION_ROLE_TITLE_ERROR;
        confirmSubmissionModalRef.result.then((result) => {
          if (result != null) {
            if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_HOME) {
              this.router.navigate([AppConfig.ROUTE_URL_HOME]);
            }
          }
        })
      }
    }
  }

  
  /**
   * Method retrieves user info from database
   */
  private getUserProfile() {

    this.ngxShowLoader.show();
    let userProfileId: any = this.loginSession.getEditUserId();

    if (userProfileId != null) {
      //Get updated user info from database
      this.dataService.getRecordById(userProfileId).subscribe((responseData: {}) => {

        let response: any = responseData;
        if (response.docs.length > 0) {
          let documents: any[] = response.docs;
          documents.forEach(element => {
            this.documentId = element.id;
            console.log(element.data().User);
            let userInfo = element.data();
            if (userInfo.User.id == userProfileId) {
              this.currentEditUserProfile = userInfo;

              this.fillProfileInfo();
              this.fillDocuments();
              this.fillMedications();
              this.fillTreatments();

            }
            else {
              this.showProfileError();
            }
          });
        }
        else {
          this.showProfileError();
        }
      }, err => {
        this.ngxShowLoader.hide();
        console.log(err);

      });
    }
    else {

      this.showProfileError();
    }


  }

   /**
   * Fills form profile data
   */
  private fillProfileInfo() {
    let userProfile: any = this.currentEditUserProfile;

    if (userProfile != null) {
      this.UserInfoGroup.controls.UserRegistrationLastName.patchValue(userProfile.User.lastName);
      this.UserInfoGroup.controls.UserRegistrationFirstName.patchValue(userProfile.User.firstName);
      this.UserInfoGroup.controls.UserRegistrationMiddleName.patchValue(userProfile.User.middleName);
      this.UserInfoGroup.controls.UserName.patchValue(userProfile.User.userName);
      this.UserInfoGroup.controls.UserRegistrationPhone.patchValue(userProfile.User.phone);
      this.UserInfoGroup.controls.UserRegistrationSymtoms.patchValue(userProfile.User.history.symtoms);
      this.UserInfoGroup.controls.UserRegistrationGroupId.patchValue(userProfile.User.insurance.groupNumber);
      this.UserInfoGroup.controls.UserRegistrationPolicyNumber.patchValue(userProfile.User.insurance.policyNumber);
      this.UserInfoGroup.controls.UserRegistrationPolicyPhoneNumber.patchValue(userProfile.User.insurance.contactInfo);
      this.UserInfoGroup.controls.UserRegistrationDateOfBirth.patchValue(userProfile.User.insurance.birthDate);
      this.UserInfoGroup.controls.PhotoUrl.patchValue(userProfile.User.photoUrl);
      this.UserInfoGroup.controls.Provider.patchValue(userProfile.User.provider);
      this.UserInfoGroup.controls.Id.patchValue(userProfile.User.id);
      this.UserInfoGroup.controls.UserRegistrationEmail.patchValue(userProfile.User.email);
      this.UserInfoGroup.controls.AuthorizationToken.patchValue(userProfile.User.authToken);
      this.photoUrl = userProfile.User.photoUrl;
      this.userName = userProfile.User.firstName + ' ' + userProfile.User.lastName;
      this.ngxShowLoader.hide()
    }
    else {
      this.showProfileError();

    }
  }

  private fillMedications() {
    let userProfile: Users = this.currentEditUserProfile

    if (userProfile != null) {
      ELEMENT_MEDICATIONS_DATA = [];
      if (userProfile.User.medications != null) {
        userProfile.User.medications.forEach(element => {
          let medication: IMedications = {
            id: null,
            medName: element.medName,
            medEndDate: element.medEndDate,
            medStartData: element.medStartDate,
            prescriber: element.prescriber
          }
          ELEMENT_MEDICATIONS_DATA.push(medication);
        });

        this.updateMedicationListView();
      }
    }
  }

  updateMedicationListView() {
    this.dataSourceMed = new MatTableDataSource<IMedications>(ELEMENT_MEDICATIONS_DATA);
    this.dataSourceMed.sort = this.sortMeds;
    this.dataSourceMed.paginator = this.paginatorMeds;
  }

  private fillDocuments() {
    let userProfile: Users = this.currentEditUserProfile;

    if (userProfile != null) {
      ELEMENT_DOCUMENT_DATA = [];
      if (userProfile.User.documents != null) {
        userProfile.User.documents.forEach(element => {
          let document: IDocuments = {
            id: null,
            name: element.name,
            type: element.type,
            url: element.url

          }
          ELEMENT_DOCUMENT_DATA.push(document);
        });

        this.updateDocumentsListView();
      }
    }
  }

  updateDocumentsListView() {
    this.dataSourceDocs = new MatTableDataSource<IDocuments>(ELEMENT_DOCUMENT_DATA);
    this.dataSourceDocs.sort = this.sortDocs;
    this.dataSourceDocs.paginator = this.paginatorDocs;
  }

  private fillTreatments() {
    let userProfile: Users = this.currentEditUserProfile;

    if (userProfile != null) {
      ELEMENT_TREATMENT_DATA = [];
      if (userProfile.User.treatments != null) {
        userProfile.User.treatments.forEach(element => {
          let treatment: ITreatments = {
            id: null,
            planName: element.planName,
            planNurse: element.planNurse,
            planStartDate: element.planStartDate
          }
          ELEMENT_TREATMENT_DATA.push(treatment);
        });

        this.updateTreatmentsListView();
      }
    }
  }

  updateTreatmentsListView() {
    this.dataSourceTreatments = new MatTableDataSource<ITreatments>(ELEMENT_TREATMENT_DATA);
    this.dataSourceTreatments.sort = this.sortTreatments;
    this.dataSourceTreatments.paginator = this.paginatorTreatments;
  }

  updateProfile() {

    this.SPINNER_MESSAGE = AppConfig.SPINNER_UPDATING_PROFILE_MESSAGE;
    this.ngxShowLoader.show()
    let documents: Documents[] = null;
    let medications: Medications[] = null;
    let treatments: TreatmentPlans[] = null;


    let userProfileGridInfo: Users = this.currentEditUserProfile;

    if (userProfileGridInfo.User.treatments != null) {
      documents = userProfileGridInfo.User.documents;
    }

    if (userProfileGridInfo.User.medications != null) {
      medications = userProfileGridInfo.User.medications;
    }

    if (userProfileGridInfo.User.documents != null) {
      treatments = userProfileGridInfo.User.treatments;
    }

    let extendedInfo: ExtendedClientInfo = {
      symtoms: this.UserInfoGroup.controls.UserRegistrationSymtoms.value
    }

    let insurnaceInfo: InsuranceInfo = {
      birthDate: this.UserInfoGroup.controls.UserRegistrationDateOfBirth.value,
      contactInfo: this.UserInfoGroup.controls.UserRegistrationPolicyPhoneNumber.value,
      groupNumber: this.UserInfoGroup.controls.UserRegistrationGroupId.value,
      policyNumber: this.UserInfoGroup.controls.UserRegistrationPolicyNumber.value,

    }

    let clientProfile: ClientInfo = {
      id: this.UserInfoGroup.controls.Id.value,
      firstName: this.UserInfoGroup.controls.UserRegistrationFirstName.value,
      lastName: this.UserInfoGroup.controls.UserRegistrationLastName.value,
      middleName: this.UserInfoGroup.controls.UserRegistrationMiddleName.value,
      email: this.UserInfoGroup.controls.UserRegistrationEmail.value,
      phone: this.UserInfoGroup.controls.UserRegistrationPhone.value,
      provider: this.UserInfoGroup.controls.Provider.value,
      photoUrl: this.UserInfoGroup.controls.PhotoUrl.value,
      insurance: insurnaceInfo,
      history: extendedInfo,
      userName: this.UserInfoGroup.controls.UserName.value,
      accountInfo: {
        createDate: new Date().toISOString(),
        modifyDate: new Date().toISOString(),
        isAdmin: false
      },
      documents: documents,
      treatments: treatments,
      medications: medications
    }

    let userProfile: Users = {
      User: clientProfile
    }

    this.dataService.updateProfileDb(userProfile, this.documentId).then(res => {
      this.ngxShowLoader.hide()
      const confirmSubmissionModalRef = this.modalService.open(SubmissionStatusModalComponent, { size: 'lg', backdrop: 'static' })

      confirmSubmissionModalRef.componentInstance.enableContinue = false;
      confirmSubmissionModalRef.componentInstance.enableHome = false;
      confirmSubmissionModalRef.componentInstance.modal_body = AppConfig.MODAL_BODY_PROFILE_UPDATE_SUCCESS;
      confirmSubmissionModalRef.componentInstance.modal_header_title = AppConfig.MODAL_HEADER_PROFILE_UPDATE_TITLE_SUCCESSFUL;
      confirmSubmissionModalRef.result.then((result) => {
        if (result != null) {
          this.dataService.sendEmail(userProfile.User.email, userProfile.User.firstName);
          if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_CONTINUE) {
            window.location.reload();
          }
          else if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_HOME) {
            this.router.navigate([AppConfig.ROUTE_URL_HOME]);

          }
        }
      })
    })
  }

  /**
   * Method persists User object back to database
   * @param profile User Object
   */
  updateProfileDb(profile: Users) {
    return new Promise<any>((resolve, reject) => {

      this.fireStoreService.collection(AppConfig.DATABASE_TABLE_USER)
        .doc(this.documentId).set(profile);

    })
  }

   /**
   * Displays there was a issue load profile and sends back to login screen
   */
  showProfileError() {
    this.ngxShowLoader.hide();

    const confirmSubmissionModalRef = this.modalService.open(RegistrationModalComponent, { size: 'sm', backdrop: 'static' })
    //confirmSubmissionModalRef.componentInstance.modal_body = AppConfig.MODAL_PROFILE_LOAD_ERROR;
    //confirmSubmissionModalRef.componentInstance.modal_header_title = AppConfig.MODAL_PROFILE_LOAD_TITLE_ERROR;
    confirmSubmissionModalRef.result.then((result) => {
      if (result != null) {
        if (result.loginStatus == AppConfig.ROUTE_DIRECTION_REGISTER) {
          this.router.navigate([AppConfig.ROUTE_URL_REGISTER]);
        }
        else if (result.loginStatus == AppConfig.ROUTE_DIRECTION_MYINFO) {

          this.fillProfileInfo();
        }


      }
    })
  }

}
