<form>
    <div class="modal-header">
        <h4 class="modal-title">
            {{modal_header_title}}
        </h4>
    </div>
    <div class="modal-body">
        <p>
            {{modal_body}}
        </p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="openClientPortal()">{{UserActionProceed}}</button>
        <button class="btn btn-outline-secondary" (click)="hideModal()">{{UserActionClose}}</button>
       
    </div>
</form>