<div class="container">
    <div class="row">
        <div class="col-12">
            <h1>Services</h1>
        </div>
    </div>
    <div class="row">
        
        <div class="col-6">
            <div class="card border-0 shadow clear-card">
                <div class="card-body">
                    Lajeans will educate and provide awareness to the community to improve mental health outcomes crossing all age groups. Lajeans will work to provide a safe, comfortable, nonjudgmental environment that will enhance mental health treatment needed by many. Historically, mental illness was not addressed as people were afraid or ashamed of the stigma surrounding mental illness. Lajeans will provide treatment in a HIPPA compliant environment. Our goal is to expand our mission and values in the community through clients sharing and advising others of the excellent service received and referring friends, family and others
                    <br /><br />
When one goes to a boutique to purchase an eclectic piece, they expect to purchase a unique, one of a kind piece. They do not want to see their purchase on someone else. Similarly, Lajeans Mental Health Boutique will provided services based specifically on an individuals, physical, emotional and spiritual needs. Treatment will be unique to each individual.<br /><br />
Lajeans Mental Health Boutique will provide mental health treatment in a holistic manner. Treatment will be planned and administered on an individual basis. Through experience, Lajeans knows when one receives healthcare of any type they want to be listened to and heard. Lajeans will listen to your concerns and address your symptoms.
<br />
<hr>
<h3><b>Qualifications</b></h3>

<b>License:</b> Ohio / APRN.CNP.022060
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="card border-0 shadow clear-card">
                <div class="card-body">
                    <h3><b>Issues</b></h3>
                    <ul>
                        <li>ADHD</li>
                        <li>Addiction</li>
                        <li>Alcohol Use</li>
                        <li>Anger Management</li>
                        <li>Antisocial Personality</li>
                        <li>Anxiety</li>
                        <li>Asperger's Syndrome</li>
                        <li>Autism</li>
                        <li>Behavioral Issues</li>
                        <li>Bipolar Disorder</li>
                        <li>Borderline Personality</li>
                        <li>Child</li>
                        <li>Chronic Illness</li>
                        <li>Chronic Impulsivity</li>
                        <li>Chronic Relapse</li>
                        <li>Codependency</li>
                        <li>Coping Skills</li>
                        <li>Depression</li>
                        <li>Developmental Disorders</li>
                        <li>Divorce</li>
                        <li>Domestic Abuse</li>
                        <li>Domestic Violence</li>
                        <li>Drug Abuse</li>
                        <li>Eating Disorders</li>
                        <li>Emotional Disturbance</li>
                        <li>Family Conflict</li>
                        <li>Grief</li>
                        <li>Hoarding</li>
                        <li>Infidelity</li>
                        </ul>
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="card border-0 shadow clear-card">
                <div class="card-body">
                    
                    <ul>
                        <li>Intellectual Disability</li>
                        <li>Learning Disabilities</li>
                        <li>Life Transitions</li>
                        <li>Marital and Premarital</li>
                        <li>Narcissistic Personality</li>
                        <li>Obsessive-Compulsive (OCD)</li>
                        <li>Oppositional Defiance</li>
                        <li>Parenting</li>
                        
                        <li>Pregnancy, Prenatal, Postpartum</li>
                        <li>Racial Identity</li>
                        <li>Relationship Issues</li>
                        <li>School Issues</li>
                        <li>Self Esteem</li>
                        <li>Self-Harming</li>
                        <li>Sex Therapy</li>
                        <li>Sexual Abuse</li>
                        <li>Sexual Addiction</li>
                        <li>Sleep or Insomnia</li>
                        <li>Spirituality</li>
                        <li>Substance Use</li>
                        <li>Suicidal Ideation</li>
                        <li>Teen Violence</li>
                        <li>Testing and Evaluation</li>
                        
                        <li>Trauma and PTSD</li>
                        <li>Traumatic Brain Injury</li>
                        <li>Women's Issues</li>
                        </ul>
                </div>
            </div>
        </div>

    </div>
    <br /><br />
    <div class="row">
        <div class="col-6">
            
        </div>
        <div class="col-3">
            <div class="card border-0 shadow clear-card">
                <div class="card-body">
                    <h3><b>Mental Health</b></h3>
                    <ul>
                        <li>Dissociative Disorders</li>
                        <li>Elderly Persons Disorders</li>
                        <li>Impulse Control Disorders</li>
                        </ul>
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="card border-0 shadow clear-card">
                <div class="card-body">
                    <ul>
                        <li>Mood Disorders</li>
                        <li>Personality Disorders</li>
                        <li>Psychosis</li>
                        <li>Thinking Disorders</li>
                        </ul>
                    
                </div>
            </div>
        </div>

    </div>
   
    
</div>