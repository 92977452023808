<form [formGroup]="RegistrationGroup">
    <div class="modal-header">
        <h2 class="modal-title">
            Submit Review Login
        </h2>
        <button type="button" class="close" (click)="closeModal()" aria-label="Close">
            <span>&times;</span>
        </button>
    </div>
    
        <div class="card border-0 shadow">
            <div class="card card-body">
                <h3 class="text-left mb-4">To submit a review, we use Google login providers to retrieve profile information for submitting a review. <br /><br /> Please click Login with Google to continue</h3>
                <h4 *ngIf="invalidLogin">Invalid username/password combination</h4>
                <fieldset>
                    <!--
                    <div class="form-group has-error">
                        <input formControlName="UserName" class="form-control input-lg" placeholder="Username" type="text">
                        
                    </div>
                    <div class="form-group has-success">
                        <input formControlName="Password" class="form-control input-lg" placeholder="Password" type="password">
                    </div>

                    <input [disabled]="submitting" (click)="userLogin()" class="btn btn-lg btn-primary btn-block" value="Login" type="button">

                    <div class="txt1 text-center p-t-54 p-b-20">
                        <a href="#" class="nav-link" (click)="setRegisterUsernamePassword($event)">Click Here To Register</a>
                        <span>
                            Or Sign Up Using
                        </span>
                    </div>

                    <button (click)="socialsigin('facebook')" class="btn-block loginBtn loginBtn--facebook">
                        Login with Facebook
                    </button>
                    -->

                    <button (click)="socialsigin('gmail')" class=" btn-block loginBtn loginBtn--google">
                        Login with Google
                    </button>

                </fieldset>
            </div>
        </div>
   

</form>