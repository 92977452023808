<form [formGroup]="RegistrationGroup">
    <div class="card border-0 shadow my-5 clear-card">
        <div class="card card-body">
            <h3 class="text-center mb-4">Welcome to Lajeans</h3>

            <fieldset>
                <div class="form-group has-error">
                    <input formControlName="UserName" class="form-control input-lg" placeholder="Username" name="email"
                        type="text">
                </div>
                <div class="form-group has-success">
                    <input formControlName="Password" class="form-control input-lg" placeholder="Password"
                        name="password" value="" type="password">
                </div>

                <input class="btn btn-lg btn-primary btn-block" value="Login" type="button">
               
                <div class="txt1 text-center p-t-54 p-b-20">
                    <a href="#" class="nav-link">Click Here To Register</a>
                    <span>
                        Or Sign Up Using
                    </span>
                </div>

                <button (click)="socialsigin('facebook')" class="btn-block loginBtn loginBtn--facebook">
                    Login with Facebook
                </button>

                <button (click)="socialsigin('gmail')" class=" btn-block loginBtn loginBtn--google">
                    Login with Google
                </button>

            </fieldset>
        </div>
    </div>
</form>