import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { MatTable, MatTableDataSource } from '@angular/material/table';

import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SubmissionStatusModalComponent } from 'src/app/modals/submission-status-modal/submission-status-modal.component';
import { AppConfig, IUserProfile } from 'src/app/model/constants/app-config';
import { LajeansdataApiService } from 'src/app/service/lajeansdata-api.service';
import { LajeanshareApiService } from 'src/app/service/lajeanshare-api.service';

let ELEMENT_DATA: IUserProfile[] = [];

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit, AfterViewInit {

  AdministrationFormGroup: any;
  constructor(private dataService: LajeansdataApiService,
    private modalService: NgbModal,
    private router: Router,
    private loginSession: LajeanshareApiService,
    private formBuilder: FormBuilder) { }


  searchColumns: string[] = ['id', 'profile_id', 'lastName', 'firstName', 'email', 'phone', 'edit', 'delete'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) table: MatTable<any>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  _userProfileSearchResults: any;

  ngOnInit(): void {
    this.AdministrationFormGroup = this.formBuilder.group({})

    this.getUserPermission();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;

  }

  getUserPermission()
  {
    let userProfile: any = this.loginSession.getCurrentUserProfileData();
    
    if(userProfile != null)
    {
      if(userProfile.User.accountInfo.isAdmin == true)
      {
        this.getUserList();
      }
      else
      {
        const confirmSubmissionModalRef = this.modalService.open(SubmissionStatusModalComponent, { size: 'lg', backdrop: 'static' })

        confirmSubmissionModalRef.componentInstance.enableHome = false;
        confirmSubmissionModalRef.componentInstance.modal_body = AppConfig.MODAL_BODY_ADMINISTRATION_ROLE_ERROR;
        confirmSubmissionModalRef.componentInstance.modal_header_title = AppConfig.MODAL_HEADER_ADMINISTRATION_ROLE_TITLE_ERROR;
        confirmSubmissionModalRef.result.then((result) => {
          if (result != null) {
            if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_HOME) {
              this.router.navigate([AppConfig.ROUTE_URL_HOME]);
            }
          }
        })
      }
    }
  }

  getUserList() {
    this.dataService.getUserInfo().subscribe((responseData: {}) => {
      let response: any = responseData;
      if (response.length > 0) {
        ELEMENT_DATA = [];
        this._userProfileSearchResults = response;

        this._userProfileSearchResults.forEach(element => {
          if (element.User != null) {
            let user: IUserProfile = {
              id: element.User.id,
              profile_id: element.User.insurance.groupNumber,
              email: element.User.email,
              firstName: element.User.firstName,
              lastName: element.User.lastName,
              phone: element.User.phone
            }
            console.log(element);
            ELEMENT_DATA.push(user);
          }
        });

        this.updateUserListView();

      }

    })
  }

  getUserRecord(recordId: any)
  {
    this.loginSession.setEditUserId(recordId.id)
    this.router.navigate([AppConfig.ROUTE_URL_EDITUSER]);
  }

  updateUserListView()
  {
    this.dataSource = new MatTableDataSource<IUserProfile>(ELEMENT_DATA);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }



}
