import { Injectable } from "@angular/core";
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn:'root'
})

export class AppConfig {

    private static readonly secretKey = "6uO(Cr)/l{T9Bs&*[+rfOdM@Q2wdv=";
  
    public static encrypt(value : string) : string{
      return CryptoJS.AES.encrypt(value, this.secretKey.trim(), { mode: CryptoJS.mode.ECB }).toString();
    }
  
    public static decrypt(textToDecrypt : string){
      return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
    }

    public static readonly USER_ID_GOOGLE = 'asmith@lajeansmhb.org';

    //CLIENT_IDS - Facebook & Google
    public static readonly CLIENT_ID_FACEBOOK = '434917924373914';
    public static readonly CLIENT_ID_GOOGLE = '446112142447-1g73ieglb0g3g5en4j838ner5i9eh1cs.apps.googleusercontent.com';    
    public static readonly APIKEY_GOOGLE = 'AIzaSyCYiZ_D9gDDSDXhkIjjpVFqFyZySQBex4I';
    public static readonly PLATFORM_TYPE_FACEBOOK = 'facebook';
    public static readonly PLATFORM_TYPE_GMAIL = 'gmail';
    public static readonly PLATFORM_TYPE_EMAILPASSWORD = 'emailPassword';
    public static readonly PROVIDER_FIREBASE_USERNAME = 'Default Provider'

    //EMAILJS Notification Settings
    public static readonly SMTP_USERNAME = 'support@lajeansmhb.org';
    public static readonly EMAILJS_SERVICEID = 'service_px2srz5';
    public static readonly EMAILJS_TEMPLATEID = 'template_m9h5yww';
    public static readonly EMAILJS_USERID = 'user_BzC9RW3sUNpUujS1ctZg5';
    
    //Local Storage Keys
    public static readonly LOCALSTORAGE_CURRENTUSERLOGIN_INFO = "CurrentUserlogininfo";
    public static readonly LOCALSTORAGE_REGISTERUSER_PROFILE_INFO ="RegisteringUserlogininfo"

    //Web application URL routes
    public static readonly ROUTE_URL_REGISTER = 'register';
    public static readonly ROUTE_URL_REVIEW = 'review';
    public static readonly ROUTE_URL_MYINFO = 'myInfo';
    public static readonly ROUTE_URL_HOME = '';
    public static readonly ROUTE_URL_ABOUT = 'about';
    public static readonly ROUTE_URL_EDITUSER = 'editUser';
    public static readonly ROUTE_URL_ADMINISTRATION = 'administration';
    public static readonly MODAL_ROUTE_DIRECTION_CONTINUE = 'continue';
    public static readonly MODAL_ROUTE_DIRECTION_OPEN = 'open';
    public static readonly MODAL_ROUTE_DIRECTION_HIDE = 'hide';
    public static readonly MODAL_ROUTE_DIRECTION_HOME = 'home';


    //FireStore Database section
    public static readonly DATABASE_TABLE_USER = "Users";
    public static readonly DATABASE_TABLE_REVIEWS = "Reviews";

    //Modal section
    public static readonly MODAL_BODY_SUBMISSION_SUCCESS = "Thank you for registering with Lajeans Mental Health Boutique.  You will receive and email shortly with your registration information.  In additon, we will be contacting you shortly to schedule an appointment.";
    public static readonly MODAL_BODY_PROFILE_UPDATE_SUCCESS = "Your user profile has been successfully updated. An email will be sent to you shortly notifying you of the changes to your profile";
    public static readonly MODAL_BODY_REVIEW_SUBMISSION_SUCCESS = "Thank you for submitting your comments and feedback to Lajeans Mental Health Boutique.  Your feedback allow us to make improvements and continue to offer you service execellence and quality care";
    
    
    public static readonly MODAL_HEADER_PROFILE_UPDATE_TITLE_SUBMISSION_REVIEW = "Submission Review";
    public static readonly MODAL_HEADER_SUBMISSION_TITLE_SUCCESSFUL = "Submission Successful";
    public static readonly MODAL_HEADER_PROFILE_UPDATE_TITLE_SUCCESSFUL = "Update Successful";
    public static readonly MODAL_PROFILE_LOAD_TITLE_ERROR = "Profile Error";
    public static readonly MODAL_PROFILE_EXIST_TITLE_ERROR = "Profile Already Exist Error";
    public static readonly MODAL_PROFILE_LOAD_ERROR = "There was an issue loading your profile. You can click 'Close' to return to login screen or 'Continue' to register.";
    public static readonly MODAL_PROFILE_CREATE_USER = "There was an issue creating your user profile. You can click Close to return to login screen or Continue to register Please refresh the page and try again.";
    public static readonly MODAL_PROFILE_CREATE_DATABASE_USER = "There was an issue storing your registration information in the database. Please refresh the page and try again.";
    public static readonly MODAL_PROFILE_EXIST_ERROR = "It looks like you've already registered before. Please try logging in again.  If you still are not able to login, try closing and re-opening your browser and sign in again.";
    public static readonly MODAL_CLIENTPORTAL_TITLE = "Client Portal Login";
    public static readonly MODAL_CLIENTPORTAL_BODY = "This action will open the Client Portal Patient login screen in a different window, so that you can login to Patient portal.  Your session at Lajeans will remain open and you can navigate back to this tab at anytime.  To proceed to the Client Patient Portal, please click Proceed to continue.  If you do not wish to proceed to the portal, click Close";
    public static readonly MODAL_BODY_ADMINISTRATION_ROLE_ERROR = "Your current user role does not permit you to view the administration module.  Please contact your system administrator to grant you this role.";
    public static readonly MODAL_HEADER_ADMINISTRATION_ROLE_TITLE_ERROR = "Profile Error";
    public static readonly MESSAGE_REGISTRATION_WELCOME = "Thank you for registering with Lajeans Mental Health Boutique.  Please fill out the information below to get started.  After you have registered, you will receive a confirmation email to confirming your registration and a licensed therapist will contact you shortly.";
    public static readonly MESSAGE_REVIEWER_WELCOME = "Thank you for submitting a review with Lajeans Mental Health Boutique.  Please fill out the information below and click Submit.  Thank you.";

    //Spinner section
    public static readonly SPINNER_UPDATE_RECORD_MESSAGE = "Submitting Registration";
    public static readonly SPINNER_UPDATE_SUBMIT_REVIEW = "Submitting Review";
    public static readonly SPINNER_LOAD_PROFILE_MESSAGE = "Loading User Profile";
    public static readonly SPINNER_UPDATING_PROFILE_MESSAGE = "Loading User Profile";
    public static readonly SPINNER_CHECKEXIST_PROFILE_MESSAGE = "Checking If User Profile Exist";
    public static readonly SPINNER_RETRIEVING_PROFILE_MESSAGE = "Checking If User Profile Exist";
    
    //Application Routes
    public static readonly ROUTE_DIRECTION_MYINFO = "MyInfo";
    public static readonly ROUTE_DIRECTION_REGISTER = "Register";
    public static readonly ROUTE_DIRECTION_HOME = "Home";
    public static readonly ROUTE_DIRECTION_SUBMITREVIEW = "Submit Review";

    public static readonly USER_ROUTE_ACTION_LOGIN = "Login";
    public static readonly USER_ROUTE_ACTION_LOGOUT = "Logout";
    public static readonly USER_ROUTE_ACTION_MYINFO = "My Info";
    public static readonly USER_ROUTE_ACTION_CLIENTADMINISTRATION = "Client Administration";
    public static readonly USER_ROUTE_ACTION_CLIENTPORTAL = "Client Portal"

    public static readonly USER_CLIENTPORTAL_ACTION_PROCEED = "Proceed";
    public static readonly USER_CLIENTPORTAL_ACTION_CLOSE = "Close";
    public static readonly URL_CLIENTPORTAL = "https://patientonlineportal.com/idp/account/signin?ReturnUrl=%2Fidp%2Fissue%2Fwsfed%3Fwa%3Dwsignin1.0%26wtrealm%3Dhttps%253a%252f%252fpatientonlineportal.com%252f%26wctx%3Drm%253d0%2526id%253dpassive%2526ru%253d%25252f%26wct%3D2020-03-11T16%253a38%253a49Z%26wreply%3Dhttps%253a%252f%252fpatientonlineportal.com%252f&wa=wsignin1.0&wct=2020-03-11T16%3A38%3A49Z&wctx=rm%3D0%26id%3Dpassive%26ru%3D%252f&wreply=https%3A%2F%2Fpatientonlineportal.com%2F&wtrealm=https%3A%2F%2Fpatientonlineportal.com%2F";

    public static readonly TOOLBAR_USER_GREETING = "Welcome ";

    //Asset Image Paths
    public static readonly ASSET_IMAGEICON_DEFAULT_USER = 'assets/img/default_user.jpg';

    //Document Types
    public static readonly DOCUMENT_TYPE_PDF = "Pdf";
}

export interface IUserProfile
{
    id: String;
    profile_id: String;
    firstName: String;
    lastName: String;
    email: String;
    phone: String;
}

export interface IReviewSubmission
{
    id: String;
    profile_id: String;
    firstName: String;
    lastName: String;
    email: String;
    phone: String;
    comment: String;
    commentId: String;
    submissionDate: String;
}


export interface IDocuments
{
    id: String;
    name: String;
    type: String;
    url: String;
}

export interface ITreatments
{
    id: String;
    planName: String;
    planStartDate: String;
    planNurse: String;
}

export interface IMedications
{
    id: String;
    medName: String;
    medStartData: String;
    medEndDate: String;
    prescriber: String;
}