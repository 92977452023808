import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConfig } from 'src/app/model/constants/app-config';

@Component({
  selector: 'app-client-portal-modal',
  templateUrl: './client-portal-modal.component.html',
  styleUrls: ['./client-portal-modal.component.scss']
})
export class ClientPortalModalComponent implements OnInit {

  modal_body = AppConfig.MODAL_CLIENTPORTAL_BODY;;
  modal_header_title = AppConfig.MODAL_CLIENTPORTAL_TITLE;
  UserActionProceed = AppConfig.USER_CLIENTPORTAL_ACTION_PROCEED;
  UserActionClose = AppConfig.USER_CLIENTPORTAL_ACTION_CLOSE;


  constructor(public activeModal: NgbActiveModal,
    private router: Router,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.formBuilder.group({
      id: ['']
    })
  }


  hideModal()
  {
    this.activeModal.close({action: AppConfig.USER_CLIENTPORTAL_ACTION_CLOSE });
  
  }

  openClientPortal()
  {
    this.activeModal.close({action: AppConfig.USER_CLIENTPORTAL_ACTION_PROCEED});
  }

 

}
