import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordStrengthBarComponent } from '../password-strength-bar/password-strength-bar.component';

@Component({
  selector: 'app-password-control',
  templateUrl: './password-validator.component.html',
  styleUrls: ['./password-validator.component.scss']
})
export class PasswordValidatorComponent implements OnInit {
  
  @ViewChild('passwordstrength') _passwordstrength: PasswordStrengthBarComponent;
  PasswordValidatorGroup: any;
 
  barLabel: string = "Password strength:";
  constructor(private formBuilder: FormBuilder,
    public passwordStrength: PasswordStrengthBarComponent) { }

  ngOnInit(): void {
    this.PasswordValidatorGroup = this.formBuilder.group({
      Password: ['', [Validators.required, Validators.minLength(8)]],
      ConfirmPassword: ['', Validators.required]
    },{
      validator: MustMatch('Password', 'ConfirmPassword')
    });
  }

  get registerFormControl() { return this.PasswordValidatorGroup.controls; }

  public clearPasswords()
  {
    this.PasswordValidatorGroup.controls.Password.patchValue('');
    this.PasswordValidatorGroup.controls.ConfirmPassword.patchValue('');
  }
  

}

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}
