<div class="container">
    <div class="row">
        <div class="col-12">
            <h1>SPRAVATO® Treatment</h1>
        </div>
    </div>

    <div class="row">
        <div class="col-4">
            <div class="card border-0 shadow my-5 clear-card-image">
                <div class="card-body">
                    <img src="../../../assets/img/p248.png">
                </div>
            </div>
        </div>
        <div class="col-8">
            <div class="card border-0 shadow clear-card">
                <div class="card-body p-5">
                    SPRAVATO® is a prescription medicine, used along with an antidepressant taken by mouth to treat:
                    <br /><br />
                    <ul>
                        <li> Adults with treatment-resistant depression (TRD)</li>
                        <li> Depressive symptoms in adults with major depressive disorder (MDD) with suicidal thoughts
                            or actions</li>
                    </ul>

                    Depressive symptoms in adults with major depressive disorder (MDD) with suicidal thoughts or actions
                    SPRAVATO® is not for use as a medicine to prevent or relieve pain (anesthetic). It is not known if
                    SPRAVATO® is safe or effective as an anesthetic medicine.

                    It is not known if SPRAVATO® is safe and effective for use in preventing suicide or in reducing
                    suicidal thoughts or actions. SPRAVATO® is not for use in place of hospitalization if your
                    healthcare provider determines that hospitalization is needed, even if improvement is experienced
                    after the first dose of SPRAVATO®.

                    It is not known if SPRAVATO® is safe and effective in children.
                    <br /><br />
                    For more information and to locate Lajeans Spravato treatment center click <a target="_blank"
                        href="https://www.spravato.com/find-a-center?zipcode=44146&by_distance=all">here </a>
                </div>
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-4">
            <div class="card border-1 shadow my-5 clear-card">
                <h2 class="card-title text-center">Ketamine for Major Depression</h2>
                <div class="card-body">
                    Ketamine was once used mainly as an anesthetic on battlefields and in operating rooms.
                    Now this medication is gaining ground as a promising treatment for some cases of
                    major depression...
                    <br /><br />
                    ​​​Click to Read More
                    <br /><br />
                    <a target="_blank"
                        href="https://www.health.harvard.edu/blog/ketamine-for-major-depression-new-tool-new-questions-2019052216673">health.harvard.edu</a>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="card border-1 shadow my-5 clear-card">
                <h2 class="card-title text-center">Ketamine Helps with Depression</h2>
                <div class="card-body">
                    On March 5, the Food and Drug Administration (FDA) approved the first truly
                    new medication for major depression in decades.
                    ​​ <br /><br />
                    Click to Read More
                    <br /><br />
                    <a href="https://www.yalemedicine.org/news/ketamine-depression"
                        target="_blank">​yalemedicine.org</a>

                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="card border-1 shadow my-5 clear-card">
                <h2 class="card-title text-center">​Largest Study on Ketamine</h2>
                <div class="card-body">
                    ​​​​The study is a statistical analysis of 8 million reports, from 2004 to 2016,
                    from the FDA's Adverse Effect Reporting System database.
                    ​​ <br /><br />
                    ​​Click to Read More
                    <br /><br />
                    <a target="_blank" href="https://www.nature.com/articles/s41598-017-01590-x">nature.com</a>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="card border-1 shadow clear-card">
                <h2 class="card-title text-center">​​How Do You Not Offer This?</h2>
                <div class="card-body">
                    Gerard Sanacora, a professor of psychiatry at Yale University,
                    has treated hundreds of severely depressed patients with low doses of ketamine, an anesthetic and...
                    ​​ <br /><br />
                    ​Click to Read More
                    ​​ <br /><br />
                    <a href="https://www.npr.org/sections/health-shots/2017/03/20/520169959/ketamine-for-severe-depression-how-do-you-not-offer-this-drug-to-people?)?)" target="_blank">npr.org</a>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="card border-1 shadow clear-card">
                <h2 class="card-title text-center">National Alliance on Mental Illness</h2>
                <div class="card-body">
                    Cristina Cusin, MD, Harvard University psychiatrist and ketamine researcher, talks and answers questions about use of ketamine for depression.
                    ​​ <br /><br />
                    Click to Read More
                    ​​ <br /><br />
                    <a target="_blank" href="https://www.nami.org/About-NAMI/NAMI-News/2017/Ask-the-Doctor-Webinar-on-Ketamine-for-Depression">nami.org</a>
                       
                </div>
            </div>
        </div>

    </div>
</div>