<form [formGroup]="PasswordValidatorGroup">
    <div class="row clearfix">
        <div class="col-6">
            <div class="form-group">
                <label>Password:</label>
                <input required placeholder="Password" formControlName="Password" class="form-control" type="password" minlength="8" maxlength="50">
                <div *ngIf="registerFormControl.Password.invalid" class="text-danger">
                    <div *ngIf="registerFormControl.Password.errors.required">Password is required</div>
                    <div *ngIf="registerFormControl.Password.errors.minlength">Password must be at least 8 characters</div>
                    <div *ngIf="registerFormControl.Password.errors.mustMatch">Passwords must match</div>
                </div>
                <app-password-strength-bar [passwordToCheck]="registerFormControl.Password.value" [barLabel]="barLabel">

                </app-password-strength-bar>
            </div>
        </div>
        <div class="col-6">

        </div>
     
    </div>
    <div class="row clearfix">
        <div class="col-6">
            <div class="form-group">
                <label>Confirm Password:</label>
                <input required placeholder="Confirm Password" formControlName="ConfirmPassword" class="form-control" type="password" minlength="8" maxlength="50">
            </div>
            <div *ngIf="registerFormControl.ConfirmPassword.invalid" class="text-danger">
                <div *ngIf="registerFormControl.ConfirmPassword.errors.required">Confirm Password is required</div>
                <div *ngIf="registerFormControl.ConfirmPassword.errors.minlength">Confirm Password must be at least 8 characters</div>
                <div *ngIf="registerFormControl.ConfirmPassword.errors.mustMatch">Passwords must match</div>
            </div>
        </div>
        <div class="col-6">

        </div>
       
    </div>
</form>