import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConfig, IReviewSubmission } from 'src/app/model/constants/app-config';
import { LajeansdataApiService } from 'src/app/service/lajeansdata-api.service';
import { LajeanshareApiService } from 'src/app/service/lajeanshare-api.service';

@Component({
  selector: 'app-submission-review-modal',
  templateUrl: './submission-review-modal.component.html',
  styleUrls: ['./submission-review-modal.component.scss']
})
export class SubmissionReviewModalComponent implements OnInit {
  ReviewerGroup: any;
  modal_header_title;
  _userReviewSearchResults: any;
  constructor(private formbuilder: FormBuilder,
    private dataService: LajeansdataApiService,
    private loginSession: LajeanshareApiService,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {

    this.ReviewerGroup = this.formbuilder.group({
     ReviewerDate:[''],
     ReviewerComments: ['']
    })

    this.getCommentById();
  }

  getCommentById() {
    let commentId = this.loginSession.getCommentId();
    this.dataService.getReviewsByCommentId(commentId).subscribe((responseData: {}) => {
      let response: any = responseData;
      const format = 'MM/dd/yyyy';
      const locale = 'en-US';

      if (response.size > 0) {
        
        this._userReviewSearchResults = response.docs;

        this._userReviewSearchResults.forEach(element => {
          const formattedDate = formatDate(element.data().submissionDate, format, locale);

          let user: IReviewSubmission = {
            id: element.data().id,
            profile_id: "",
            email: element.data().email,
            firstName: element.data().firstName,
            lastName: element.data().lastName,
            phone: element.data().phone,
            comment: element.data().comments,
            commentId: element.data().commentId,
            submissionDate: formattedDate
          }
          this.ReviewerGroup.controls.ReviewerComments.patchValue(element.data().comments);
          console.log(element);
        });

      }

    })
  }


  hideModal()
  {
    this.activeModal.close({status: AppConfig.MODAL_ROUTE_DIRECTION_HIDE});
    
  }

}
