<form [formGroup]="UserRegistrationGroup">
    <div *ngIf="ngxShowLoader">
        <ngx-spinner>
            <p class="spinner_loading">{{SPINNER_MESSAGE}}</p>
        </ngx-spinner>
    </div>
    <fieldset class="border p-2">
        <div class="row">
            <div class="col-2 pl-5" [hidden]="registerEmailPassword">
                <div class="card border-0 shadow my-5 clear-card">
                    <img class="card-img-top" src="{{photoUrl}}" alt="Card image cap">
                    <div class="card card-body">
                        {{userName}}
                    </div>
                </div>
            </div>

            <div class="col-1 pl-5" [hidden]="!registerEmailPassword">
              
            </div>

            <div class="col-10">
                <div class="card border-0 shadow my-5 clear-card">
                    <div class="card-header">
                        <h2>Client Registration Information</h2>
                    </div>
                    <div class="card card-body">
                        <div class="row clearfix">
                            <div class="col-12">
                                <h3>
                                    {{userWelcomeMessage}}
                                </h3>
                                
                            </div>
                        </div>
                        <hr>
                        <div class="row clearfix">
                            <div class="col-12">
                                <fieldset class="border p-2">

                                    <legend>Client Contact Information</legend>

                                    <div class="row clearfix" [hidden]="registerEmailPassword">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Authentication Provider:</label>
                                                <input readonly formControlName="Provider" class="form-control inputAsLabel"
                                                    type="text">
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>User Id:</label>
                                                <input readonly formControlName="Id" class="form-control inputAsLabel" type="text">
                                            </div>
                                        </div>
                                        <div class="col-4">

                                        </div>

                                    </div>

                                    <div class="row clearfix">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>*Last Name:</label>
                                                <input maxlength="50" required
                                                    formControlName="UserRegistrationLastName" class="form-control"
                                                    type="text">
                                                <span
                                                    *ngIf="registerFormControl.UserRegistrationLastName.invalid && (registerFormControl.UserRegistrationLastName.dirty || registerFormControl.UserRegistrationLastName.touched)"
                                                    class="text-danger">Last Name is required.
                                                </span>
                                            </div>

                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>*First Name:</label>
                                                <input maxlength="50" required
                                                    formControlName="UserRegistrationFirstName" class="form-control"
                                                    type="text">
                                                <span
                                                    *ngIf="registerFormControl.UserRegistrationFirstName.invalid && (registerFormControl.UserRegistrationFirstName.dirty || registerFormControl.UserRegistrationFirstName.touched)"
                                                    class="text-danger">
                                                    First Name is required.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Middle Name:</label>
                                                <input maxlength="50" formControlName="UserRegistrationMiddleName"
                                                    class="form-control" type="text">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row clearfix">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>*Email Address:</label>
                                                <input required formControlName="UserRegistrationEmail"
                                                    class="form-control" type="email">
                                                <span
                                                    *ngIf="registerFormControl.UserRegistrationEmail.invalid && (registerFormControl.UserRegistrationEmail.dirty || registerFormControl.UserRegistrationEmail.touched)"
                                                    class="text-danger">
                                                    Email Address is required.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>*Primary Phone:</label>
                                                <input required mask="(000) 000-0000" placeholder="(000) 000-0000"
                                                    formControlName="UserRegistrationPhone" class="form-control"
                                                    type="text">
                                                <span
                                                    *ngIf="registerFormControl.UserRegistrationPhone.invalid && (registerFormControl.UserRegistrationPhone.dirty || registerFormControl.UserRegistrationPhone.touched)"
                                                    class="text-danger">
                                                    Phone Number is required.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-4">

                                        </div>

                                    </div>
                                    <div>

                                    </div>
                                </fieldset>
                                <br />
                                <div class="row clearfix" [hidden]="!registerEmailPassword">
                                    <div class="col-12">
                                        <fieldset class="border p-2">
                                            <legend>Client Password</legend>
                                            <app-password-control #passwordcontrol>
                                            </app-password-control>
                                        </fieldset>
                                    </div>
                                </div>

                                <br />
                                <fieldset class="border p-2">
                                    <legend>History of Present Illess:</legend>
                                    <div class="row clearfix">
                                        <div class="col-12">
                                            <label>Client Symtoms: (Optional)</label>
                                            <div class="form-group">
                                                <textarea maxlength="2024" formControlName="UserRegistrationSymtoms"
                                                    rows="4" cols="110"></textarea>
                                            </div>
                                        </div>

                                    </div>


                                </fieldset>
                                <br />
                                <br />

                                <fieldset class="border p-2">
                                    <legend>Insurance Information:</legend>
                                    <div class="row clearfix">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>*Group Identifier:</label>
                                                <input maxlength="50" required formControlName="UserRegistrationGroupId"
                                                    class="form-control" type="text">
                                                <span
                                                    *ngIf="registerFormControl.UserRegistrationGroupId.invalid && (registerFormControl.UserRegistrationGroupId.dirty || registerFormControl.UserRegistrationGroupId.touched)"
                                                    class="text-danger">
                                                    Group Identifier is required.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>*Policy Number:</label>
                                                <input maxlength="50" required
                                                    formControlName="UserRegistrationPolicyNumber" class="form-control"
                                                    type="text">
                                                <span
                                                    *ngIf="registerFormControl.UserRegistrationPolicyNumber.invalid && (registerFormControl.UserRegistrationPolicyNumber.dirty || registerFormControl.UserRegistrationPolicyNumber.touched)"
                                                    class="text-danger">
                                                    Policy Number is required.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>*Insurance Contact Information:</label>
                                                <input mask="(000) 000-0000" placeholder="(000) 000-0000" required
                                                    formControlName="UserRegistrationPolicyPhoneNumber"
                                                    class="form-control" type="text">
                                                <span
                                                    *ngIf="registerFormControl.UserRegistrationPolicyPhoneNumber.invalid && (registerFormControl.UserRegistrationPolicyPhoneNumber.dirty || registerFormControl.UserRegistrationPolicyPhoneNumber.touched)"
                                                    class="text-danger">
                                                    Group Identifier is required.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>*Date of Birth:</label>
                                                <input required formControlName="UserRegistrationDateOfBirth"
                                                    class="form-control" type="date">
                                                <span
                                                    *ngIf="registerFormControl.UserRegistrationDateOfBirth.invalid && (registerFormControl.UserRegistrationDateOfBirth.dirty || registerFormControl.UserRegistrationDateOfBirth.touched)"
                                                    class="text-danger">
                                                    Date of Birth is required.
                                                </span>

                                            </div>
                                        </div>

                                    </div>


                                </fieldset>
                            </div>
                        </div>
                    </div>

                    <div class="row clearfix p-2">
                        <div class="col-3">
                            <div class="form-group">

                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">

                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">

                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <input [hidden]="registerEmailPassword" (click)="saveProfile()" class="btn btn-md btn-primary btn-block" value="Submit"
                                    type="button">
                                <input [hidden]="!registerEmailPassword" (click)="saveProfileAsEmailPassword()" class="btn btn-md btn-primary btn-block" value="Submit"
                                    type="button">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </fieldset>

</form>