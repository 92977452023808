<div class="container">
    <div class="row">
        <div class="col-12">
            <h1>Transgender Treatment and Therapy</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <div class="card border-0 shadow my-5 clear-card-image">
                <div class="card-body">
                    <img src="../../../assets/img/trans-hormone-graphic.jpg">
                </div>
            </div>
        </div>
        <div class="col-8">
            <div class="card border-0 shadow my-5 clear-card">
                <div class="card-body p-5">
                    Transgender hormone therapy, also sometimes called Hormone replacement therapy, is a form of hormone therapy in which sex hormones and other hormonal medications are administered to transgender or gender nonconforming individuals for the purpose of more closely aligning their secondary sexual characteristics with their gender identity. This form of hormone therapy is given as one of two types, based on whether the goal of treatment is feminization or masculinization:
                    <br /><br />

Feminizing hormone therapy – for transgender women or transfeminine people; consists of estrogens and antiandrogens
<br /><br />

Masculinizing hormone therapy – for transgender men or transmasculine people; consists of androgens
<br /><br />

Some intersex people may also undergo hormone therapy, either starting in childhood to confirm the sex they were assigned at birth, or later in order to align their sex with their gender identity. Non-binary people may also undergo hormone therapy in order to achieve a desired balance of sex hormones or to pass as a desired gender. 
                </div>
            </div>
        </div>

    </div>
</div>