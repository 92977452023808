import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialAuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { AppConfig } from 'src/app/model/constants/app-config';
import { LajeansdataApiService } from 'src/app/service/lajeansdata-api.service';
import { LajeanshareApiService } from 'src/app/service/lajeanshare-api.service';
import { AngularFireAuth } from '@angular/fire/auth';


@Component({
  selector: 'app-registration-modal',
  templateUrl: './registration-modal.component.html',
  styleUrls: ['./registration-modal.component.scss']
})
export class RegistrationModalComponent implements OnInit {

  RegistrationGroup: any;
  loginStatus: any;
  invalidLogin = false;
  submitting = false;

  constructor(private formbuilder: FormBuilder,
    private firebaseAuth: AngularFireAuth,
    public activeModal: NgbActiveModal,
    private router: Router,
    private socialProvider: SocialAuthService,
    private dataService: LajeansdataApiService,
    private loginSession: LajeanshareApiService) { }

  ngOnInit(): void {

    this.RegistrationGroup = this.formbuilder.group({
      UserName: [''],
      Password: ['']
    })
  }

  /**
   * Login provider for social network
   * @param platform 
   */
  socialsigin(platform: string) {
    let userProfile: any = this.loginSession.getUserProfileData();

    if (userProfile != null) {
      this.loginStatus = AppConfig.ROUTE_DIRECTION_SUBMITREVIEW;
      this.hideModal();
    }
    else {
      let socialPlatformProvider;
      if (platform == AppConfig.PLATFORM_TYPE_FACEBOOK) {
        socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
      }
      else if (platform == AppConfig.PLATFORM_TYPE_GMAIL) {
        socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
      }
      this.socialProvider.signIn(socialPlatformProvider).then((userdata) => {
        this.loginStatus = AppConfig.ROUTE_DIRECTION_SUBMITREVIEW; 
        this.loginSession.setUserProfileData(userdata);
        this.hideModal();
        
          
      });
    }

  }

  userLogin() {
    this.submitting = true;
    let username: string  = this.RegistrationGroup.controls.UserName.value;
    let password: string = this.RegistrationGroup.controls.Password.value;

    if ((username != null && username != '') && (password != null && password != ''))
    {
      
     // password = AppConfig.encrypt(password.trim());

      this.firebaseAuth.signInWithEmailAndPassword(username.trim(), password)
        .then((userCredential) => {
          // Signed in
          let user = userCredential.user;
          
          this.dataService.getRecordByEmail(user.email).subscribe((responseData: {}) => {

          let response: any = responseData;
          if (response.docs.length > 0) {
            let documents: any[] = response.docs;
            documents.forEach(element => {
              console.log(element.data().User);
              let userInfo = element.data();
              if (userInfo.User.email == user.email) {
                this.loginSession.setCurrentUserProfileData(userInfo);
                this.loginStatus = AppConfig.ROUTE_DIRECTION_MYINFO;
                this.submitting = false;
                this.hideModal();
              }
            });
          }
          else {
            
            this.submitting = false;
            this.invalidLogin = true;
          }
        });
          // ...
        })
        .catch((error) => {
          this.submitting = false;
          this.invalidLogin = true;
          var errorCode = error.code;
          var errorMessage = error.message;
        });
    }
    else
    {
      this.submitting = false;
      this.invalidLogin = true;
      
    }
  }

  hideModal() {
    this.activeModal.close({ loginStatus: this.loginStatus });
  }

  closeModal() {
    this.activeModal.close();
    this.router.navigate([AppConfig.ROUTE_URL_HOME]);
  }

  /**
   * Method is called when user clicks registering with username/password
   * @param action
   */
  setRegisterUsernamePassword(action) {
    action.preventDefault();
    this.activeModal.close();
    this.loginSession.setRegisterEmailPassword(true);
    this.router.navigate([AppConfig.ROUTE_URL_REGISTER]);
  }

}
