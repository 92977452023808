<div class="container">
    <div class="row">
        <div class="col-12">
            <h1>VIVITROL® Treatment</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <div class="card border-0 shadow my-5 clear-card-image">
                <div class="card-body">
                    <img src="../../../assets/img/vivitrol-logo.png">
                </div>
            </div>
        </div>
        <div class="col-8">
            <div class="card border-0 shadow my-5 clear-card">
                <div class="card-body p-5">
                    VIVITROL® (naltrexone for extended-release injectable suspension) is a once-monthly treatment proven
                    to help reduce heavy drinking days in alcohol-dependent patients when used with counseling.1,2
                    <br /><br />
                    VIVITROL and counseling help reinforce recovery for one month at a time while you work on the
                    psychological aspects of addiction through counseling.1
                    <br /><br />
                    Before starting VIVITROL, you must be opioid-free for a minimum of 7–14 days to avoid sudden opioid
                    withdrawal.
                    <br /><br />
                    For more information and to locate Lajeans Vivitrol treatment center click <a target="_blank"
                        href="https://www.vivitrol.com/alcohol-dependence/find-a-treatment-provider">here </a>
                </div>
            </div>
        </div>

    </div>
</div>