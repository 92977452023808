import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { AppConfig } from '../model/constants/app-config';

@Injectable({
  providedIn: 'root'
})
export class LajeanshareApiService {

  constructor() { }

  //Login session object from social providers
  private loginData: any;
  //Session object from already logged in user
  private loginStatus: any;
  private registerEmailPassword: boolean = false;
  itemValue = new Subject<string>();
  
  editUser: any;
  commentId: any;

  setCommentId(comment: any)
  {
    this.commentId = comment;
  }

  getCommentId()
  {
    return this.commentId;
  }

  /**
 * Set login type as username/password
 * @param message 
 */
setEditUserId(message: any)
{
  this.editUser = message;
}

/**
 * Get login type of username/password
 */
getEditUserId()
{
  return this.editUser;
}


/**
 * Set login type as username/password
 * @param message 
 */
setRegisterEmailPassword(message:boolean)
{
  this.registerEmailPassword = message;
}

/**
 * Get login type of username/password
 */
getRegisterEmailPassword()
{
  return this.registerEmailPassword;
}


/**
 * Set login status
 * @param message 
 */
setLoginStatus(message:any)
{
  this.loginStatus = message;
}

/**
 * Get login status
 */
getLoginStatus()
{
  return this.loginStatus;
}

/**
 * Set registration profile data
 * @param message 
 */
setUserProfileData(message:any)
{
  
  localStorage.setItem(AppConfig.LOCALSTORAGE_REGISTERUSER_PROFILE_INFO, JSON.stringify(message));
}

/**
 * Get registration profile data
 */
getUserProfileData()
{
  return JSON.parse(localStorage.getItem(AppConfig.LOCALSTORAGE_REGISTERUSER_PROFILE_INFO));
}

/**
 * Set registered user profile data
 */
setCurrentUserProfileData(message:any)
{
  this.itemValue.next(message);
  localStorage.setItem(AppConfig.LOCALSTORAGE_CURRENTUSERLOGIN_INFO, JSON.stringify(message));
}

/**
 * Getregistered user profile data
 */
getCurrentUserProfileData()
{
  let userInfo = localStorage.getItem(AppConfig.LOCALSTORAGE_CURRENTUSERLOGIN_INFO);

  if(userInfo != null)
  {
    let authenticatedUser = JSON.parse(userInfo);

    return authenticatedUser;

  }
  else
  {
    return null;
  }
}


}
