import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubmissionStatusModalComponent } from 'src/app/modals/submission-status-modal/submission-status-modal.component';
import { ClientInfo, Documents, ExtendedClientInfo, InsuranceInfo, Medications, TreatmentPlans, Users } from 'src/app/model/client/client-info.model';
import { AppConfig } from 'src/app/model/constants/app-config';
import { v4 as uuidv4 } from 'uuid';




import { LajeansdataApiService } from 'src/app/service/lajeansdata-api.service';
import { LajeanshareApiService } from 'src/app/service/lajeanshare-api.service';
import { PasswordValidatorComponent } from '../registeration/password-validator/password-validator.component';
import { AngularFireAuth } from '@angular/fire/auth';



@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss']
})

export class UserRegistrationComponent implements OnInit {
  @ViewChild('passwordcontrol', { static: false }) _passwordcontrol: PasswordValidatorComponent;


  UserRegistrationGroup: any;
  SPINNER_MESSAGE
  photoUrl;
  authProvider;
  userId;
  userName;
  registerEmailPassword = false;
  userWelcomeMessage = AppConfig.MESSAGE_REGISTRATION_WELCOME;


  constructor(private formbuilder: FormBuilder,

    private firebaseAuth: AngularFireAuth,
    private modalService: NgbModal,
    private router: Router,
    private loginSession: LajeanshareApiService,
    private dataService: LajeansdataApiService,
    private fireStoreService: AngularFirestore,
    public ngxShowLoader: NgxSpinnerService) { }

  ngOnInit(): void {
    this.UserRegistrationGroup = this.formbuilder.group({
      AuthorizationToken: [''],
      Id: [''],
      Provider: [''],
      UserName: [''],
      PhotoUrl: [''],
      UserRegistrationLastName: ['', Validators.required],
      UserRegistrationFirstName: ['', Validators.required],
      UserRegistrationMiddleName: [''],
      UserRegistrationSuffix: [''],
      UserRegistrationEmail: ['', Validators.compose([Validators.required, Validators.email])],
      UserRegistrationPhone: ['', Validators.required],
      UserRegistrationGroupId: ['', Validators.required],
      UserRegistrationPolicyNumber: ['', Validators.required],
      UserRegistrationPolicyPhoneNumber: ['', Validators.required],
      UserRegistrationSymtoms: [''],
      UserRegistrationDateOfBirth: ['', Validators.required]
    })

    this.getSocialProfileInfo();
  }

  /**
   * Getter method to allow form to retrieve controls shorthand
   */
  get registerFormControl() {
    return this.UserRegistrationGroup.controls;
  }

  /**
   * Fills registration fields
   */
  getSocialProfileInfo() {
    let userProfile: any = this.loginSession.getUserProfileData();

    if (userProfile != null) {
      this.UserRegistrationGroup.controls.UserRegistrationLastName.patchValue(userProfile.lastName);
      this.UserRegistrationGroup.controls.UserRegistrationFirstName.patchValue(userProfile.firstName);
      this.UserRegistrationGroup.controls.UserName.patchValue(userProfile.name);
      this.UserRegistrationGroup.controls.PhotoUrl.patchValue(userProfile.photoUrl);
      this.UserRegistrationGroup.controls.Provider.patchValue(userProfile.provider);
      this.UserRegistrationGroup.controls.Id.patchValue(userProfile.id);
      this.UserRegistrationGroup.controls.UserRegistrationEmail.patchValue(userProfile.email);
      this.UserRegistrationGroup.controls.AuthorizationToken.patchValue(userProfile.authToken);
      this.photoUrl = userProfile.photoUrl;
      this.authProvider = userProfile.provider;
      this.userId = userProfile.id;
      this.userName = userProfile.name;
    }
    else {
      const selfRegister = this.loginSession.getRegisterEmailPassword();

      if (selfRegister == true) {
        this.registerEmailPassword = true;
      }
      else {
        this.showProfileError();
      }


    }
  }

  /**
   * Method builds User object
   */
  saveProfile() {
    this.SPINNER_MESSAGE = AppConfig.SPINNER_CHECKEXIST_PROFILE_MESSAGE;
    this.ngxShowLoader.show();

    //See if user already has an account by checking for previous Id and Email
    this.dataService.getRecordById(this.UserRegistrationGroup.controls.Id.value).subscribe((getRecordByIdResponseData: {}) => {
      this.dataService.getRecordByEmail(this.UserRegistrationGroup.controls.UserRegistrationEmail.value).subscribe((getRecordByEmailResponseData: {}) => {

        let responseByEmail: any = getRecordByEmailResponseData;
        let responseById: any = getRecordByIdResponseData;
        if (responseById.docs.length > 0 || responseByEmail.docs.length > 0) {
          this.showProfileExistError();
        }
        else {
          this.SPINNER_MESSAGE = AppConfig.SPINNER_UPDATE_RECORD_MESSAGE;

          if (this.UserRegistrationGroup.valid) {

            let med1: Medications = {
              medName: "Sample Medication Name",
              medStartDate: new Date().toISOString(),
              medEndDate: "",
              prescriber: "Prescriber Name"
            }

            let med2: Medications = {
              medName: "Sample Medication Name",
              medStartDate: new Date().toISOString(),
              medEndDate: "",
              prescriber: "Prescriber Name"
            }

            let medications: Medications[] = [];

            medications.push(med1);
            medications.push(med2);

            let document: Documents = {
              name: "Registration Information",
              type: AppConfig.DOCUMENT_TYPE_PDF,
              url: ""
            }

            let document2: Documents = {
              name: "Treatment Acceptance",
              type: AppConfig.DOCUMENT_TYPE_PDF,
              url: ""
            }

            let documents: Documents[] = []
            documents.push(document);
            documents.push(document2);


            let treatment: TreatmentPlans = {
              planName: "Treatment plan 1",
              planNurse: "Nurse1",
              planStartDate: new Date().toISOString()
            }

            let treatment2: TreatmentPlans = {
              planName: "Treatment plan 2",
              planNurse: "Nurse2",
              planStartDate: new Date().toISOString()
            }

            let treatments: TreatmentPlans[] = [];
            treatments.push(treatment);
            treatments.push(treatment2);

            let extendedInfo: ExtendedClientInfo = {
              symtoms: this.UserRegistrationGroup.controls.UserRegistrationSymtoms.value
            }

            let insurnaceInfo: InsuranceInfo = {
              birthDate: this.UserRegistrationGroup.controls.UserRegistrationDateOfBirth.value,
              contactInfo: this.UserRegistrationGroup.controls.UserRegistrationPolicyPhoneNumber.value,
              groupNumber: this.UserRegistrationGroup.controls.UserRegistrationGroupId.value,
              policyNumber: this.UserRegistrationGroup.controls.UserRegistrationPolicyNumber.value,

            }

            let clientProfile: ClientInfo = {
              id: this.UserRegistrationGroup.controls.Id.value,
              firstName: this.UserRegistrationGroup.controls.UserRegistrationFirstName.value,
              lastName: this.UserRegistrationGroup.controls.UserRegistrationLastName.value,
              middleName: this.UserRegistrationGroup.controls.UserRegistrationMiddleName.value,
              email: this.UserRegistrationGroup.controls.UserRegistrationEmail.value,
              phone: this.UserRegistrationGroup.controls.UserRegistrationPhone.value,
              provider: this.UserRegistrationGroup.controls.Provider.value,
              photoUrl: this.UserRegistrationGroup.controls.PhotoUrl.value,
              insurance: insurnaceInfo,
              history: extendedInfo,
              accountInfo: {
                createDate: new Date().toISOString(),
                modifyDate: new Date().toISOString(),
                isAdmin: false
              },
              userName: this.UserRegistrationGroup.controls.UserName.value,
              treatments: treatments,
              documents: documents
            }

            let userProfile: Users = {
              User: clientProfile
            }

            let result = this.dataService.saveProfileDb(userProfile).then((result) => {
              this.ngxShowLoader.hide()
              const confirmSubmissionModalRef = this.modalService.open(SubmissionStatusModalComponent, { size: 'lg', backdrop: 'static' })

              confirmSubmissionModalRef.componentInstance.enableGotoProfile = false;
              confirmSubmissionModalRef.componentInstance.enableHome = false;
              confirmSubmissionModalRef.componentInstance.modal_body = AppConfig.MODAL_BODY_SUBMISSION_SUCCESS;
              confirmSubmissionModalRef.componentInstance.modal_header_title = AppConfig.MODAL_HEADER_SUBMISSION_TITLE_SUCCESSFUL;
              confirmSubmissionModalRef.result.then((result) => {
                if (result != null) {
                  this.dataService.sendEmail(userProfile.User.email, userProfile.User.firstName);
                  this.loginSession.setCurrentUserProfileData(userProfile);
                  if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_OPEN) {
                    this.router.navigate([AppConfig.ROUTE_URL_MYINFO]);
                  }
                  else if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_HOME) {
                    this.router.navigate([AppConfig.ROUTE_URL_HOME]);

                  }
                }
              })
            }).catch((error) => {
              console.log(error.message);
              this.ngxShowLoader.hide();
              this.showCreateProfileError();
            })
          }
          else {
            this.ngxShowLoader.hide();
            this.UserRegistrationGroup.markAllAsTouched();
          }
        }

      });
    });
  }

  /**
   * Method builds User object
   */
  saveProfileAsEmailPassword() {
    this.SPINNER_MESSAGE = AppConfig.SPINNER_CHECKEXIST_PROFILE_MESSAGE;
    this.ngxShowLoader.show();

    if (this.registerEmailPassword == true && this._passwordcontrol.PasswordValidatorGroup.invalid) {
      this._passwordcontrol.PasswordValidatorGroup.markAllAsTouched();
      this.UserRegistrationGroup.markAllAsTouched();
      this.ngxShowLoader.hide();
    }
    else {
      //See if user already has an account
      this.dataService.getRecordByEmail(this.UserRegistrationGroup.controls.UserRegistrationEmail.value).subscribe((responseData: {}) => {

        let response: any = responseData;

        if (response.docs.length > 0) {
          this.showCreateProfileDbExistError();
        }
        else {
          this.SPINNER_MESSAGE = AppConfig.SPINNER_UPDATE_RECORD_MESSAGE;

          if (this.UserRegistrationGroup.valid) {
            let userId = uuidv4();

            let med1: Medications = {
              medName: "Sample Medication Name",
              medStartDate: new Date().toISOString(),
              medEndDate: "",
              prescriber: "Prescriber Name"
            }

            let med2: Medications = {
              medName: "Sample Medication Name",
              medStartDate: new Date().toISOString(),
              medEndDate: "",
              prescriber: "Prescriber Name"
            }

            let medications: Medications[] = [];

            medications.push(med1);
            medications.push(med2);

            let document: Documents = {
              name: "Registration Information",
              type: AppConfig.DOCUMENT_TYPE_PDF,
              url: ""
            }

            let document2: Documents = {
              name: "Treatment Acceptance",
              type: AppConfig.DOCUMENT_TYPE_PDF,
              url: ""
            }

            let documents: Documents[] = []
            documents.push(document);
            documents.push(document2);


            let treatment: TreatmentPlans = {
              planName: "Treatment plan 1",
              planNurse: "Nurse1",
              planStartDate: new Date().toISOString()
            }

            let treatment2: TreatmentPlans = {
              planName: "Treatment plan 2",
              planNurse: "Nurse2",
              planStartDate: new Date().toISOString()
            }

            let treatments: TreatmentPlans[] = [];
            treatments.push(treatment);
            treatments.push(treatment2);


            let extendedInfo: ExtendedClientInfo = {
              symtoms: this.UserRegistrationGroup.controls.UserRegistrationSymtoms.value
            }

            let insurnaceInfo: InsuranceInfo = {
              birthDate: this.UserRegistrationGroup.controls.UserRegistrationDateOfBirth.value,
              contactInfo: this.UserRegistrationGroup.controls.UserRegistrationPolicyPhoneNumber.value,
              groupNumber: this.UserRegistrationGroup.controls.UserRegistrationGroupId.value,
              policyNumber: this.UserRegistrationGroup.controls.UserRegistrationPolicyNumber.value,

            }

            let clientProfile: ClientInfo = {
              id: userId,
              firstName: this.UserRegistrationGroup.controls.UserRegistrationFirstName.value,
              lastName: this.UserRegistrationGroup.controls.UserRegistrationLastName.value,
              middleName: this.UserRegistrationGroup.controls.UserRegistrationMiddleName.value,
              email: this.UserRegistrationGroup.controls.UserRegistrationEmail.value,
              phone: this.UserRegistrationGroup.controls.UserRegistrationPhone.value,
              provider: AppConfig.PROVIDER_FIREBASE_USERNAME,
              photoUrl: AppConfig.ASSET_IMAGEICON_DEFAULT_USER,
              insurance: insurnaceInfo,
              history: extendedInfo,
              documents: documents,
              treatments: treatments,
              medications: medications,
              userName: this.UserRegistrationGroup.controls.UserName.value,
              accountInfo: {
                createDate: new Date().toISOString(),
                modifyDate: new Date().toISOString(),
                isAdmin: false

              }
            }

            let userProfile: Users = {
              User: clientProfile
            }
            let password: string = this._passwordcontrol.PasswordValidatorGroup.controls.Password.value;


            this.dataService.createUserWithEmailAndPassword(clientProfile.email, password)
              .then((result) => {
                console.log(result.user);
                let createUserresult = this.dataService.saveProfileDb(userProfile)
                  .then((result) => {
                    this.ngxShowLoader.hide()
                    const confirmSubmissionModalRef = this.modalService.open(SubmissionStatusModalComponent, { size: 'lg', backdrop: 'static' })

                    confirmSubmissionModalRef.componentInstance.enableGotoProfile = false;
                    confirmSubmissionModalRef.componentInstance.enableHome = false;
                    confirmSubmissionModalRef.componentInstance.modal_body = AppConfig.MODAL_BODY_SUBMISSION_SUCCESS;
                    confirmSubmissionModalRef.componentInstance.modal_header_title = AppConfig.MODAL_HEADER_SUBMISSION_TITLE_SUCCESSFUL;
                    confirmSubmissionModalRef.result.then((result) => {
                      if (result != null) {
                        this.dataService.sendEmail(userProfile.User.email, userProfile.User.firstName);
                        this.loginSession.setCurrentUserProfileData(userProfile);
                        if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_OPEN) {
                          this.router.navigate([AppConfig.ROUTE_URL_MYINFO]);
                        }
                        else if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_HOME) {
                          this.router.navigate([AppConfig.ROUTE_URL_HOME]);

                        }
                      }
                    })
                  }).catch((error) => {
                    console.log(error.message);
                    this.ngxShowLoader.hide();
                    this.showCreateProfileError();
                  })

              }).catch((error) => {
                console.log(error.message)
                this.ngxShowLoader.hide();
                this.showCreateDatabaseUserError();
              })

          }
          else {
            this.ngxShowLoader.hide();
            this.UserRegistrationGroup.markAllAsTouched();
          }
        }

      });
    }
  }


  /**
   * Displays there was a issue load profile and sends back to login screen
   */
  showProfileExistError() {
    this.ngxShowLoader.hide();
    const confirmSubmissionModalRef = this.modalService.open(SubmissionStatusModalComponent, { size: 'lg', backdrop: 'static' })
    confirmSubmissionModalRef.componentInstance.modal_body = AppConfig.MODAL_PROFILE_EXIST_ERROR;
    confirmSubmissionModalRef.componentInstance.modal_header_title = AppConfig.MODAL_PROFILE_EXIST_TITLE_ERROR;
    confirmSubmissionModalRef.result.then((result) => {
      if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_OPEN) {
        this.router.navigate([AppConfig.ROUTE_URL_MYINFO]);
      }
      else if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_HOME) {
        this.router.navigate([AppConfig.ROUTE_URL_HOME]);
      }
      else if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_CONTINUE) {
        //Do nothing
      }
    })
  }

  /**
   * Displays there was a issue load profile and sends back to login screen
   */
  showCreateProfileDbExistError() {
    this.ngxShowLoader.hide();
    const confirmSubmissionModalRef = this.modalService.open(SubmissionStatusModalComponent, { size: 'lg', backdrop: 'static' })
    confirmSubmissionModalRef.componentInstance.enableContinue = false;
    confirmSubmissionModalRef.componentInstance.enableHome = false;
    confirmSubmissionModalRef.componentInstance.modal_body = AppConfig.MODAL_PROFILE_EXIST_ERROR;
    confirmSubmissionModalRef.componentInstance.modal_header_title = AppConfig.MODAL_PROFILE_EXIST_TITLE_ERROR;
    confirmSubmissionModalRef.result.then((result) => {
      if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_OPEN) {
        this.router.navigate([AppConfig.ROUTE_URL_MYINFO]);
      }
      else if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_HOME) {
        this.router.navigate([AppConfig.ROUTE_URL_HOME]);
      }
      else if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_CONTINUE) {
        //Do nothing
      }
    })
  }

  /**
   * Displays there was a issue load profile and sends back to login screen
   */
  showProfileError() {
    this.ngxShowLoader.hide();
    const confirmSubmissionModalRef = this.modalService.open(SubmissionStatusModalComponent, { size: 'lg', backdrop: 'static' })
    confirmSubmissionModalRef.componentInstance.modal_body = AppConfig.MODAL_PROFILE_LOAD_ERROR;
    confirmSubmissionModalRef.componentInstance.enableHome = false;
    confirmSubmissionModalRef.componentInstance.modal_header_title = AppConfig.MODAL_PROFILE_LOAD_TITLE_ERROR;
    confirmSubmissionModalRef.result.then((result) => {
      if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_OPEN) {
        this.router.navigate([AppConfig.ROUTE_URL_MYINFO]);
      }
      else if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_HOME) {
        this.router.navigate([AppConfig.ROUTE_URL_HOME]);
      }
      else if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_CONTINUE) {
        //Do nothing
      }
    })
  }

  /**
   * Displays there was a issue creating user record in the database
   */
  showCreateProfileError() {
    this.ngxShowLoader.hide();
    const confirmSubmissionModalRef = this.modalService.open(SubmissionStatusModalComponent, { size: 'lg', backdrop: 'static' })
    confirmSubmissionModalRef.componentInstance.modal_body = AppConfig.MODAL_PROFILE_CREATE_USER;
    confirmSubmissionModalRef.componentInstance.enableGotoProfile = true;
    confirmSubmissionModalRef.componentInstance.modal_header_title = AppConfig.MODAL_PROFILE_LOAD_TITLE_ERROR;
    confirmSubmissionModalRef.result.then((result) => {
      if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_OPEN) {
        this.router.navigate([AppConfig.ROUTE_URL_MYINFO]);
      }
      else if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_HOME) {
        this.router.navigate([AppConfig.ROUTE_URL_HOME]);
      }
      else if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_CONTINUE) {
        //Do nothing
      }
    })
  }

  /**
   * Displays there was a issue saving user record in the database
   */
  showCreateDatabaseUserError() {
    this.ngxShowLoader.hide();
    const confirmSubmissionModalRef = this.modalService.open(SubmissionStatusModalComponent, { size: 'lg', backdrop: 'static' })
    confirmSubmissionModalRef.componentInstance.modal_body = AppConfig.MODAL_PROFILE_CREATE_DATABASE_USER;
    confirmSubmissionModalRef.componentInstance.enableContinue = false;
    confirmSubmissionModalRef.componentInstance.enableHome = false;
    confirmSubmissionModalRef.componentInstance.modal_header_title = AppConfig.MODAL_PROFILE_LOAD_TITLE_ERROR;
    confirmSubmissionModalRef.result.then((result) => {
      if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_OPEN) {
        this.router.navigate([AppConfig.ROUTE_URL_MYINFO]);
      }
      else if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_HOME) {
        this.router.navigate([AppConfig.ROUTE_URL_HOME]);
      }
      else if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_CONTINUE) {
        //Do nothing
      }
    })
  }

}
