<form [formGroup]="ReviewerGroup">
    <div class="modal-header">
        <h4 class="modal-title">
            {{modal_header_title}}
        </h4>
    </div>
    <div class="modal-body">
        <fieldset class="border p-2">
            
                    <div class="card border-0 shadow my-5 clear-card">
                      
                        <div class="card card-body">
                            
                            
                            <div class="row clearfix">
                                <div class="col-12">
                                    <fieldset class="border p-2">
                                        <legend>Patient Comments:</legend>
                                        <div class="row clearfix">
                                            <div class="col-12">
    
                                                <div class="form-group">
                                                    <textarea readonly maxlength="4048" formControlName="ReviewerComments" rows="8"
                                                        minlength="10" cols="95"></textarea>
                                                    
                                                </div>
                                            </div>
    
                                        </div>
                                    </fieldset>
                                    
                                </div>
                            </div>
                        </div>
    
     
                    </div>
                
    
            
        </fieldset>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-secondary" (click)="hideModal()">Close</button>
       
    </div>
</form>