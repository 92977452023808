import { HostListener } from '@angular/core';
import { Component } from '@angular/core';
import { AppConfig } from './model/constants/app-config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'lajeansmhb-project';



  
}
