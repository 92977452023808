import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './component/navigation/navigation.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RegisterationComponent } from './component/registeration/registeration.component';
import { ToolbarComponent } from './component/toolbar/toolbar.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore'
import { AngularFireAuthModule } from '@angular/fire/auth'
import { environment } from 'src/environments/environment';
import { AboutComponent } from './component/about/about.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { UserRegistrationComponent } from './component/user-registration/user-registration.component';
import { SocialLoginModule, GoogleLoginProvider, FacebookLoginProvider, SocialAuthServiceConfig } from 'angularx-social-login'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SubmissionStatusModalComponent } from './modals/submission-status-modal/submission-status-modal.component'
import { NgxSpinnerModule } from 'ngx-spinner';
import { AdministrationComponent } from './component/administration/administration.component'

import { AppConfig } from './model/constants/app-config';
import { UserInfoComponent } from './component/user-info/user-info.component';
import { RegistrationModalComponent } from './modals/registration-modal/registration-modal.component';
import { ClientPortalModalComponent } from './modals/client-portal-modal/client-portal-modal.component';
import { PasswordStrengthBarComponent } from './component/registeration/password-strength-bar/password-strength-bar.component';
import { PasswordValidatorComponent } from './component/registeration/password-validator/password-validator.component';
import { EditUserComponent } from './component/administration/edit-user/edit-user.component';
import { VivitrolComponent } from './component/vivitrol/vivitrol.component';
import { SpravatoComponent } from './component/spravato/spravato.component';
import { AddictionComponent } from './component/addiction/addiction.component';
import { TransgenderComponent } from './component/transgender/transgender.component';
import { AnxietyComponent } from './component/anxiety/anxiety.component';
import { AuthComponent } from './component/employee/auth/auth.component';
import { LandingComponent } from './component/employee/landing/landing.component';
import { ServicesComponent } from './component/services/services.component';
import { ReviewComponent } from './component/review/review.component';
import { SubmissionReviewModalComponent } from './modals/submission-review-modal/submission-review-modal.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;


@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    RegisterationComponent,
    ToolbarComponent,
    AboutComponent,
    UserRegistrationComponent,
    SubmissionStatusModalComponent,
    AdministrationComponent,
    UserInfoComponent,
    RegistrationModalComponent,
    ClientPortalModalComponent,
    PasswordStrengthBarComponent,
    PasswordValidatorComponent,
    EditUserComponent,
    VivitrolComponent,
    SpravatoComponent,
    AddictionComponent,
    TransgenderComponent,
    AnxietyComponent,
    AuthComponent,
    LandingComponent,
    ServicesComponent,
    ReviewComponent,
    SubmissionReviewModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    SocialLoginModule,
    FontAwesomeModule,
    NgxMaskModule.forRoot(),
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
    MatSortModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    NgxSpinnerModule
  ],
  providers: [NavigationComponent,ToolbarComponent,
    PasswordStrengthBarComponent,

    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(AppConfig.CLIENT_ID_FACEBOOK)
          },
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(AppConfig.CLIENT_ID_GOOGLE)
          }
        ]
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}


