<div class="container">
    <div class="row">
        <div class="col-12">
            <h1>Anxiety and Depression</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <div class="card border-0 shadow my-5 clear-card-image">
                <div class="card-body">
                    <img src="../../../assets/img/anxiety.jpg">
                </div>
            </div>
        </div>
        <div class="col-8">
            <div class="card border-0 shadow my-5 clear-card">
                <div class="card-body p-5">
                    Depression and anxiety are different conditions, but they commonly occur together. They also have
                    similar treatments.
                    <br /><br />
                    Feeling down or having the blues now and then is normal. And everyone feels anxious from time to
                    time — it's a normal response to stressful situations. But severe or ongoing feelings of depression
                    and anxiety can be a sign of an underlying mental health disorder.
                    <br /><br />
                    Anxiety may occur as a symptom of clinical (major) depression. It's also common to have depression
                    that's triggered by an anxiety disorder, such as generalized anxiety disorder, panic disorder or
                    separation anxiety disorder. Many people have a diagnosis of both an anxiety disorder and clinical
                    depression.
                    <br /><br />
                    Symptoms of both conditions usually improve with psychological counseling (psychotherapy),
                    medications, such as antidepressants, or both. Lifestyle changes, such as improving sleep habits,
                    increasing social support, using stress-reduction techniques or getting regular exercise, also may
                    help. If you have either condition, avoid alcohol, smoking and recreational drugs. They can make
                    both conditions worse and interfere with treatment.
                </div>
            </div>
        </div>

    </div>
</div>