import { Component, OnInit } from '@angular/core';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { FormBuilder } from '@angular/forms'
import { LajeanshareApiService } from 'src/app/service/lajeanshare-api.service';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/model/constants/app-config';
import { LajeansdataApiService } from 'src/app/service/lajeansdata-api.service';



@Component({
  selector: 'client-registeration',
  templateUrl: './registeration.component.html',
  styleUrls: ['./registeration.component.scss']
})
export class RegisterationComponent implements OnInit {

  RegistrationGroup: any;

  constructor(private formbuilder: FormBuilder,
    
    private socialProvider: SocialAuthService,
    private dataService: LajeansdataApiService,
    private loginSession: LajeanshareApiService,
    private router: Router) { }

  ngOnInit(): void {

    this.RegistrationGroup = this.formbuilder.group({
      UserName: [''],
      Password: ['']
    })
  }

  /**
   * Login provider for social network
   * @param platform 
   */
  socialsigin(platform: string) {
    let socialPlatformProvider;
    if (platform == AppConfig.PLATFORM_TYPE_FACEBOOK) {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;

      this.socialProvider.signIn(socialPlatformProvider).then((userdata) => {
        this.dataService.getRecordById(userdata.id).subscribe((responseData: {}) => {
  
          let response: any = responseData;
          if (response.docs.length > 0) {
            let documents: any[] = response.docs;
            documents.forEach(element => {
              console.log(element.data().User);
              let userInfo = element.data();
              if (userInfo.User.id == userdata.id) {
                this.loginSession.setCurrentUserProfileData(userInfo);
                this.router.navigate([AppConfig.ROUTE_URL_MYINFO]);
              }
            });
          }
          else {
            this.loginSession.setUserProfileData(userdata); 
            this.router.navigate([AppConfig.ROUTE_URL_REGISTER]);
            
          }
        });
  
  
      });
    }
    else if (platform == AppConfig.PLATFORM_TYPE_GMAIL) {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;

      this.socialProvider.signIn(socialPlatformProvider).then((userdata) => {
        this.dataService.getRecordById(userdata.id).subscribe((responseData: {}) => {
  
          let response: any = responseData;
          if (response.docs.length > 0) {
            let documents: any[] = response.docs;
            documents.forEach(element => {
              console.log(element.data().User);
              let userInfo = element.data();
              if (userInfo.User.id == userdata.id) {
                this.loginSession.setCurrentUserProfileData(userInfo);
                this.router.navigate([AppConfig.ROUTE_URL_MYINFO]);
              }
            });
          }
          else {
            this.loginSession.setUserProfileData(userdata); 
            this.router.navigate([AppConfig.ROUTE_URL_REGISTER]);
            
          }
        });
  
  
      });
    }
    else if(platform == AppConfig.PLATFORM_TYPE_EMAILPASSWORD)
    {

    }
    

  }

  
}
