<form [formGroup]="UserInfoGroup" class="form form-register" novalidate>
    <div *ngIf="ngxShowLoader">
        <ngx-spinner>
            <p class="spinner_loading">{{SPINNER_MESSAGE}}</p>
        </ngx-spinner>
    </div>
    <div class="container">
        <fieldset class="border p-2">
            <div class="row clearfix">

                <div class="col-2">

                    <div class="card border-1 shadow my-5 clear-card">
                        <img class="card-img-top photo_smaller" src="{{photoUrl}}" alt="Card image cap">
                        <div class="card card-body">
                            {{userName}}
                        </div>

                    </div>
                </div>
                <div class="col-10">
                    <div class="card border-0 shadow my-5 clear-card">
                        <div class="card card-body">
                            <div class="row clearfix">
                                <div class="col-12">


                                    <fieldset>
                                        <ngb-accordion animation="true" #acc="ngbAccordion" [destroyOnHide]=false
                                            activeIds="general-info,my-medications,my-treatment-plans, my-documents">
                                            <ngb-panel id="general-info" type="primary">
                                                <ng-template ngbPanelTitle>
                                                    <span class="card-text-size">My Profile </span>
                                                </ng-template>

                                                <ng-template ngbPanelContent>
                                                    <div class="row clearfix">
                                                        <div class="col-12">
                                                            <fieldset class="border p-2">
                                                                <legend>Client Registration Information:</legend>

                                                                <div class="row clearfix">
                                                                    <div class="col-4">
                                                                        <div class="form-group">
                                                                            <label>Authentication Provider:</label>
                                                                            <input readonly formControlName="Provider"
                                                                                class="form-control inputAsLabel"
                                                                                type="text">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-8">
                                                                        <div class="form-group">
                                                                            <label>User Identifier:</label>
                                                                            <input readonly formControlName="Id"
                                                                                class="form-control inputAsLabel"
                                                                                type="text">
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                                <div class="row clearfix">
                                                                    <div class="col-4">
                                                                        <div class="form-group">
                                                                            <label>*Last Name:</label>
                                                                            <input maxlength="50" required
                                                                                formControlName="UserRegistrationLastName"
                                                                                class="form-control" type="text">
                                                                            <span
                                                                                *ngIf="registerFormControl.UserRegistrationLastName.invalid && (registerFormControl.UserRegistrationLastName.dirty || registerFormControl.UserRegistrationLastName.touched)"
                                                                                class="text-danger">Last Name is
                                                                                required.
                                                                            </span>
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-4">
                                                                        <div class="form-group">
                                                                            <label>*First Name:</label>
                                                                            <input maxlength="50" required
                                                                                formControlName="UserRegistrationFirstName"
                                                                                class="form-control" type="text">
                                                                            <span
                                                                                *ngIf="registerFormControl.UserRegistrationFirstName.invalid && (registerFormControl.UserRegistrationFirstName.dirty || registerFormControl.UserRegistrationFirstName.touched)"
                                                                                class="text-danger">
                                                                                First Name is required.
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4">
                                                                        <div class="form-group">
                                                                            <label>Middle Name:</label>
                                                                            <input maxlength="50"
                                                                                formControlName="UserRegistrationMiddleName"
                                                                                class="form-control" type="text">
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div class="row clearfix">
                                                                    <div class="col-4">
                                                                        <div class="form-group">
                                                                            <label>*Email Address:</label>
                                                                            <input required readonly
                                                                                formControlName="UserRegistrationEmail"
                                                                                class="form-control" type="text">
                                                                            <span
                                                                                *ngIf="registerFormControl.UserRegistrationEmail.invalid && (registerFormControl.UserRegistrationEmail.dirty || registerFormControl.UserRegistrationEmail.touched)"
                                                                                class="text-danger">
                                                                                Email Address is required.
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4">
                                                                        <div class="form-group">
                                                                            <label>*Primary Phone:</label>
                                                                            <input required mask="(000) 000-0000"
                                                                                placeholder="(000) 000-0000"
                                                                                formControlName="UserRegistrationPhone"
                                                                                class="form-control" type="text">
                                                                            <span
                                                                                *ngIf="registerFormControl.UserRegistrationPhone.invalid && (registerFormControl.UserRegistrationPhone.dirty || registerFormControl.UserRegistrationPhone.touched)"
                                                                                class="text-danger">
                                                                                Phone Number is required.
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4">
                                                                        <div class="form-group">

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </fieldset>
                                                            <br />
                                                            <fieldset class="border p-2">
                                                                <legend>History of Present Illess:</legend>
                                                                <div class="row clearfix">
                                                                    <div class="col-12">
                                                                        <label>Client Symtoms: (Optional)</label>
                                                                        <div class="form-group">
                                                                            <textarea maxlength="2024"
                                                                                formControlName="UserRegistrationSymtoms"
                                                                                rows="4" cols="110"></textarea>
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </fieldset>
                                                            <br />
                                                            <br />

                                                            <fieldset class="border p-2">
                                                                <legend>Insurance Information:</legend>
                                                                <div class="row clearfix">
                                                                    <div class="col-4">
                                                                        <div class="form-group">
                                                                            <label>*Group Identifier:</label>
                                                                            <input maxlength="50" required
                                                                                formControlName="UserRegistrationGroupId"
                                                                                class="form-control" type="text">
                                                                            <span
                                                                                *ngIf="registerFormControl.UserRegistrationGroupId.invalid && (registerFormControl.UserRegistrationGroupId.dirty || registerFormControl.UserRegistrationGroupId.touched)"
                                                                                class="text-danger">
                                                                                Group Identifier is required.
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4">
                                                                        <div class="form-group">
                                                                            <label>*Policy Number:</label>
                                                                            <input maxlength="50" required
                                                                                formControlName="UserRegistrationPolicyNumber"
                                                                                class="form-control" type="text">
                                                                            <span
                                                                                *ngIf="registerFormControl.UserRegistrationPolicyNumber.invalid && (registerFormControl.UserRegistrationPolicyNumber.dirty || registerFormControl.UserRegistrationPolicyNumber.touched)"
                                                                                class="text-danger">
                                                                                Policy Number is required.
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4">
                                                                        <div class="form-group">
                                                                            <label>*Insurance Contact
                                                                                Information:</label>
                                                                            <input mask="(000) 000-0000"
                                                                                placeholder="(000) 000-0000" required
                                                                                formControlName="UserRegistrationPolicyPhoneNumber"
                                                                                class="form-control" type="text">
                                                                            <span
                                                                                *ngIf="registerFormControl.UserRegistrationPolicyPhoneNumber.invalid && (registerFormControl.UserRegistrationPolicyPhoneNumber.dirty || registerFormControl.UserRegistrationPolicyPhoneNumber.touched)"
                                                                                class="text-danger">
                                                                                Group Identifier is required.
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4">
                                                                        <div class="form-group">
                                                                            <label>*Date of Birth:</label>
                                                                            <input required readonly
                                                                                formControlName="UserRegistrationDateOfBirth"
                                                                                class="form-control" type="date">
                                                                            <span
                                                                                *ngIf="registerFormControl.UserRegistrationDateOfBirth.invalid && (registerFormControl.UserRegistrationDateOfBirth.dirty || registerFormControl.UserRegistrationDateOfBirth.touched)"
                                                                                class="text-danger">
                                                                                Date of Birth is required.
                                                                            </span>

                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                    <div class="row clearfix p-2">
                                                        <div class="col-3">
                                                            <div class="form-group">

                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">

                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">

                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <input (click)="updateProfile()"
                                                                    class="btn btn-md btn-primary btn-block"
                                                                    value="Update" type="button">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </ngb-panel>
                                            <br />
                                            <br />
                                            <ngb-panel id="my-medications" type="primary">
                                                <ng-template ngbPanelTitle>
                                                    <span class="card-text-size">My Medications</span>
                                                    
                                                </ng-template>
                                                <ng-template ngbPanelContent>
                                                    <div class="row clearfix">
                                                        <div class="col-12">
                                                            <fieldset class="border p-2">
                                                                <legend>Medication Information:</legend>
                                                                <table mat-table [dataSource]="dataSourceMed"
                                                                    class="mat-elevation-z12 user-profile-table"
                                                                    matSort>
                                                                    <ng-container matColumnDef="medName">
                                                                        <th mat-header-cell *matHeaderCellDef
                                                                            mat-sort-header>Medication Name</th>
                                                                        <td mat-cell *matCellDef="let element">
                                                                            {{element.medName}}</td>
                                                                    </ng-container>
                                                                    <ng-container matColumnDef="medStartData">
                                                                        <th mat-header-cell *matHeaderCellDef
                                                                            mat-sort-header>Medication Start Date</th>
                                                                        <td mat-cell *matCellDef="let element">
                                                                            {{element.medStartData}}</td>
                                                                    </ng-container>

                                                                    <ng-container matColumnDef="medEndDate">
                                                                        <th mat-header-cell *matHeaderCellDef
                                                                            mat-sort-header>Medication End Date</th>
                                                                        <td mat-cell *matCellDef="let element">
                                                                            {{element.medEndDate}}</td>
                                                                    </ng-container>
                                                                    <ng-container matColumnDef="prescriber">
                                                                        <th mat-header-cell *matHeaderCellDef
                                                                            mat-sort-header>Prescriber</th>
                                                                        <td mat-cell *matCellDef="let element">
                                                                            {{element.prescriber}}</td>
                                                                    </ng-container>

                                                                    <tr mat-header-row
                                                                        *matHeaderRowDef="medicationsColumns"></tr>
                                                                    <tr mat-row
                                                                        *matRowDef="let row; columns: medicationsColumns">
                                                                    </tr>

                                                                </table>
                                                                
                                                                <mat-paginator [pageSizeOptions]="[5,10,15]"
                                                                    showFirstLastButtons></mat-paginator>
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="col-3">
                                                                            <div class="form-group">
                                                                                <input class="btn btn-md btn-primary btn-block" value="Add Medication" type="button">
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                            </fieldset>
                                                            
                                                        </div>
                                                        
                                                    </div>

                                                </ng-template>
                                            </ngb-panel>
                                            <br />
                                            <br />
                                            <ngb-panel id="my-treatment-plans" type="primary">
                                                <ng-template ngbPanelTitle>
                                                    <span class="card-text-size">My Treament Plans</span>
                                                </ng-template>
                                                <ng-template ngbPanelContent>
                                                    <div class="row clearfix">
                                                        <div class="col-12">
                                                            <fieldset class="border p-2">
                                                                <legend>Treatment Plan Information:</legend>
                                                                <table mat-table [dataSource]="dataSourceTreatments"
                                                                    class="mat-elevation-z12 user-profile-table"
                                                                    matSort>
                                                                    <ng-container matColumnDef="planName">
                                                                        <th mat-header-cell *matHeaderCellDef
                                                                            mat-sort-header>Plan Name</th>
                                                                        <td mat-cell *matCellDef="let element">
                                                                            {{element.planName}}</td>
                                                                    </ng-container>
                                                                    <ng-container matColumnDef="planStartDate">
                                                                        <th mat-header-cell *matHeaderCellDef
                                                                            mat-sort-header>Start Date</th>
                                                                        <td mat-cell *matCellDef="let element">
                                                                            {{element.planStartDate}}</td>
                                                                    </ng-container>

                                                                    <ng-container matColumnDef="planNurse">
                                                                        <th mat-header-cell *matHeaderCellDef
                                                                            mat-sort-header>Plan Nurse</th>
                                                                        <td mat-cell *matCellDef="let element">
                                                                            {{element.planNurse}}</td>
                                                                    </ng-container>

                                                                    <tr mat-header-row
                                                                        *matHeaderRowDef="treamentsColumns"></tr>
                                                                    <tr mat-row
                                                                        *matRowDef="let row; columns: treamentsColumns">
                                                                    </tr>

                                                                </table>
                                                                <mat-paginator [pageSizeOptions]="[5,10,15]"
                                                                    showFirstLastButtons></mat-paginator>
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="col-3">
                                                                            <div class="form-group">
                                                                                <input class="btn btn-md btn-primary btn-block" value="Add Treatment" type="button">
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                            </fieldset>

                                                        </div>
                                                    </div>

                                                </ng-template>
                                            </ngb-panel>
                                            <br />
                                            <br />
                                            <ngb-panel id="my-documents" type="primary">
                                                <ng-template ngbPanelTitle>
                                                    <span class="card-text-size">My Documents</span>
                                                </ng-template>
                                                <ng-template ngbPanelContent>
                                                    <div class="row clearfix">
                                                        <div class="col-12">
                                                            <fieldset class="border p-2">
                                                                <legend>Document Information:</legend>
                                                                
                                                                <table mat-table [dataSource]="dataSourceDocs" class="mat-elevation-z12 user-profile-table" matSort>
                                                                    <ng-container matColumnDef="name">
                                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Document Name</th>
                                                                        <td mat-cell *matCellDef="let element">{{element.name}}</td>
                                                                    </ng-container>
                                                                    <ng-container matColumnDef="type">
                                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Document Type</th>
                                                                        <td mat-cell *matCellDef="let element">{{element.type}}</td>
                                                                    </ng-container>
                                                                    
                                                                    <tr mat-header-row *matHeaderRowDef="documentsColumns"></tr>
                                                                    <tr mat-row *matRowDef="let row; columns: documentsColumns"></tr>
                                                                    
                                                                </table>
                                                                <mat-paginator [pageSizeOptions]="[5,10,15]" showFirstLastButtons></mat-paginator>
                                                                <br />
                                                                    <div class="row">
                                                                        <div class="col-3">
                                                                            <div class="form-group">
                                                                                <input class="btn btn-md btn-primary btn-block" value="Add Document" type="button">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            
                                                            </fieldset>

                                                        </div>
                                                    </div>

                                                </ng-template>
                                            </ngb-panel>

                                        </ngb-accordion>

                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
    </div>
</form>