<form>
    <div class="modal-header">
        <h4 class="modal-title">
            {{modal_header_title}}
        </h4>
    </div>
    <div class="modal-body">
        <p>
            {{modal_body}}
        </p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" [hidden]="enableGotoProfile" (click)="openProfile()">Goto My Profile</button>
        <button class="btn btn-outline-secondary" [hidden]="enableHome" (click)="goHome()">Continue</button>
        <button class="btn btn-outline-secondary" [hidden]="enableContinue" (click)="continueEdit()">Continue Editing</button>
       
    </div>
</form>