import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';


import { Observable } from 'rxjs';
import { AppConfig } from '../model/constants/app-config';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
import { SocialAuthService } from 'angularx-social-login';
import { Users } from '../model/client/client-info.model';
import { AngularFireAuth } from '@angular/fire/auth';


@Injectable({
  providedIn: 'root'
})
export class LajeansdataApiService {

  constructor(private dataService: AngularFirestore,
    private firebaseAuth: AngularFireAuth,
 
    private socialProvider: SocialAuthService) { }

  firestoreUserCollection = this.dataService.collection(AppConfig.DATABASE_TABLE_USER);

  /**
   * Method retrieves all records from db
   */
  getUserInfo(): Observable<any> {
    return this.firestoreUserCollection.valueChanges().pipe()
  }

  /**
   * Method retrieves record by user id
   * @param id
   * 
   */
  getRecordById(id: string): Observable<any> {
   const recordQuery = this.dataService.collection(AppConfig.DATABASE_TABLE_USER, query =>
      query.where('User.id', '==', id)).get().pipe();
      return recordQuery;
  }

  /**
   * Method retrieves record by user id
   * @param id
   * 
   */
   getReviewsByUserId(): Observable<any> {
    const recordQuery = this.dataService.collection(AppConfig.DATABASE_TABLE_REVIEWS, query =>
       query.where('id', '!=', null)).get().pipe();
       return recordQuery;
   }

/**
   * Method retrieves record by user id
   * @param id
   * 
   */
 getReviewsByCommentId(id: string): Observable<any> {
  const recordQuery = this.dataService.collection(AppConfig.DATABASE_TABLE_REVIEWS, query =>
     query.where('id', '==', id)).get().pipe();
     return recordQuery;
 }

  /**
   * Method retrieves record by email
   * @param email
   */
  getRecordByEmail(email: string): Observable<any> {
    const recordQuery = this.dataService.collection(AppConfig.DATABASE_TABLE_USER, query =>
       query.where('User.email', '==', email)).get().pipe();
       return recordQuery;
   }

   /**
   * Method persists User object back to database
   * @param profile User Object
   */
  updateProfileDb(profile: Users, documentId: any) {
    return new Promise<any>((resolve, reject) => {

      this.dataService.collection(AppConfig.DATABASE_TABLE_USER)
        .doc(documentId).set(profile)
        .then((res) => {
          resolve(res);
        }).catch((err) =>
        {
          console.log(err);
          reject(err);
        });
        
    })
  }

  /**
   * Method persists User object back to database
   * @param profile User Object
   */
  saveProfileDb(profile: any) {
    return new Promise<any>((resolve, reject) => {

      this.dataService.collection(AppConfig.DATABASE_TABLE_USER)
        .add(profile)
        .then((res) => {
          resolve(res);
        }).catch((err) =>
        {
          console.log(err);
          reject(err);
        });
        
    })
  }

/**
   * Method persists User object back to database
   * @param profile User Object
   */
 saveReviewsDb(review: any) {
  return new Promise<any>((resolve, reject) => {

    this.dataService.collection(AppConfig.DATABASE_TABLE_REVIEWS)
      .add(review)
      .then((res) => {
        resolve(res);
      }).catch((err) =>
      {
        console.log(err);
        reject(err);
      });
      
  })
}

  /**
   * Method creates a user account in the firebase system
   * @param emailAddress 
   * @param firstname 
   */
  createUserWithEmailAndPassword(emailAddress: string, password: string)
  {
    return new Promise<any>((resolve, reject) => {

      this.firebaseAuth.createUserWithEmailAndPassword(emailAddress, password)
      .then((res) => {
        resolve(res);
      }).catch((err) =>
      {
        console.log(err);
        reject(err);
      });
        
    })
    
  }

  

  /**
   * Method to send email through EmailJS
   * Account Management URL: https://www.emailjs.com/docs/examples/angular/
   * Username: support@lajeansmhb.org
   * @param emailAddress 
   */
  sendEmail(emailAddress: string, firstname: string) {
    emailjs.send(
      AppConfig.EMAILJS_SERVICEID, 
      AppConfig.EMAILJS_TEMPLATEID, 
      {
        firstname: firstname,
        to_email_address: emailAddress
      },
      AppConfig.EMAILJS_USERID
    ).then((result: EmailJSResponseStatus) => { 
      console.log(result.text);
    }, (error) => {
      console.log(error.text);
    });
  }

  /**
   * Removes user session
   */
  clearLocalStorage()
  {
    localStorage.removeItem(AppConfig.LOCALSTORAGE_CURRENTUSERLOGIN_INFO);
    localStorage.removeItem(AppConfig.LOCALSTORAGE_REGISTERUSER_PROFILE_INFO);
    
  }

}
