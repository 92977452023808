<form [formGroup]="ReviewerGroup">
    <div *ngIf="ngxShowLoader">
        <ngx-spinner>
            <p class="spinner_loading">{{SPINNER_MESSAGE}}</p>
        </ngx-spinner>
    </div>
    <fieldset class="border p-2">
        <div class="row">


            <div class="col-1">

            </div>

            <div class="col-11">
                <div class="card border-0 shadow my-5 clear-card">
                    <div class="card-header">
                        <h1>Patient Comments: Write a Review</h1>
                    </div>
                    <div class="card card-body">
                        <div class="row clearfix">
                            <div class="col-12">
                                <h3>
                                    {{userWelcomeMessage}}
                                </h3>

                            </div>
                        </div>
                        <hr>
                        <div class="row clearfix">
                            <div class="col-12">
                                <fieldset class="border p-2">

                                    <legend>Patient Review Information</legend>



                                    <div class="row clearfix">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>*Last Name:</label>
                                                <input readonly maxlength="50" required
                                                    formControlName="ReviewerLastName" class="form-control" type="text">
                                                <span
                                                    *ngIf="reviewerFormControl.ReviewerLastName.invalid && (reviewerFormControl.ReviewerLastName.dirty || reviewerFormControl.ReviewerLastName.touched)"
                                                    class="text-danger">Last Name is required.
                                                </span>
                                            </div>

                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>*First Name:</label>
                                                <input readonly maxlength="50" required
                                                    formControlName="ReviewerFirstName" class="form-control"
                                                    type="text">
                                                <span
                                                    *ngIf="reviewerFormControl.ReviewerFirstName.invalid && (reviewerFormControl.ReviewerFirstName.dirty || reviewerFormControl.ReviewerFirstName.touched)"
                                                    class="text-danger">
                                                    First Name is Required.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Middle Name:</label>
                                                <input maxlength="50" formControlName="ReviewerMiddleName"
                                                    class="form-control" type="text">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row clearfix">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>*Email Address:</label>
                                                <input readonly required formControlName="ReviewerEmail"
                                                    class="form-control" type="email">
                                                <span
                                                    *ngIf="reviewerFormControl.ReviewerEmail.invalid && (reviewerFormControl.ReviewerEmail.dirty || reviewerFormControl.ReviewerEmail.touched)"
                                                    class="text-danger">
                                                    Email Address is Required.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>*Primary Phone:</label>
                                                <input required mask="(000) 000-0000" placeholder="(000) 000-0000"
                                                    formControlName="ReviewerPhone" class="form-control" type="text">
                                                <span
                                                    *ngIf="reviewerFormControl.ReviewerPhone.invalid && (reviewerFormControl.ReviewerPhone.dirty || reviewerFormControl.ReviewerPhone.touched)"
                                                    class="text-danger">
                                                    Phone Number is Required.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-4">

                                        </div>

                                    </div>
                                    <div>

                                    </div>
                                </fieldset>
                                <br />
                                <fieldset class="border p-2">
                                    <legend>Patient Comments:</legend>
                                    <div class="row clearfix">
                                        <div class="col-12">

                                            <div class="form-group">
                                                <textarea maxlength="4048" formControlName="ReviewerComments" rows="8"
                                                    minlength="10" cols="147"></textarea>
                                                <span
                                                    *ngIf="reviewerFormControl.ReviewerComments.invalid && (reviewerFormControl.ReviewerComments.dirty || reviewerFormControl.ReviewerComments.touched)"
                                                    class="text-danger">
                                                    Comments is Required. Minimum length is 10 characters. Maximum
                                                    length is 4048 characters
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </fieldset>
                                

                                <div class="row clearfix p-2">
                                    <div class="col-3">
                                        <div class="form-group">
            
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
            
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <div class="form-group">
                                                <input (click)="cancelReviewSubmission()" class="btn btn-md btn-secondary btn-block"
                                                    value="Cancel" type="button">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <input (click)="saveProfile()" class="btn btn-md btn-primary btn-block" value="Submit"
                                                type="button">
                                        </div>
                                    </div>
                                </div>

                                <fieldset class="border p-2">
                                    <legend>Comment History:</legend>
                                    <div class="row clearfix">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <div class="wrapper">
                                                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 user-profile-table" matSort>
                                                                                             
                                                        <ng-container matColumnDef="lastName">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
                                                            <td mat-cell *matCellDef="let element">{{element.lastName}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="firstName">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
                                                            <td mat-cell *matCellDef="let element">{{element.firstName}}</td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="email">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email Address</th>
                                                            <td mat-cell *matCellDef="let element">{{element.email}}</td>
                                                        </ng-container>
                                                        <!--
                                                        <ng-container matColumnDef="phone">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone Number</th>
                                                            <td mat-cell *matCellDef="let element">{{element.phone}}</td>
                                                        </ng-container>
                                                        -->
                                                        <ng-container matColumnDef="submissionDate">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Submission Date</th>
                                                            <td mat-cell *matCellDef="let element">{{element.submissionDate}}</td>
                                                        </ng-container>
                                                        
                                                        <ng-container matColumnDef="open">
                                                            <th mat-header-cell *matHeaderCellDef></th>
                                                            <td mat-cell *matCellDef="let element" class="action-link">
                                                                <button type="button" class="btn btn-sm btn-success"
                                                                    (click)="getUserRecord(element)">View Comment</button>&nbsp;
                                                            </td>
                                        
                                                        </ng-container>
                                        
                                                        <tr mat-header-row *matHeaderRowDef="searchColumns"></tr>
                                                        <tr mat-row *matRowDef="let row; columns: searchColumns"></tr>
                                        
                                                    </table>
                                                    
                                                    <mat-paginator [pageSizeOptions]="[10,20,30]" showFirstLastButtons></mat-paginator>
                                        
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </fieldset>

                            </div>
                        </div>
                    </div>

 
                </div>
            </div>

        </div>
    </fieldset>

</form>