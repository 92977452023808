import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubmissionReviewModalComponent } from 'src/app/modals/submission-review-modal/submission-review-modal.component';
import { SubmissionStatusModalComponent } from 'src/app/modals/submission-status-modal/submission-status-modal.component';
import { Medications, Documents, TreatmentPlans, ExtendedClientInfo, InsuranceInfo, ClientInfo, Users, Reviewer } from 'src/app/model/client/client-info.model';
import { AppConfig, IReviewSubmission } from 'src/app/model/constants/app-config';
import { LajeansdataApiService } from 'src/app/service/lajeansdata-api.service';
import { LajeanshareApiService } from 'src/app/service/lajeanshare-api.service';
import * as uuid from 'uuid';

let ELEMENT_DATA: IReviewSubmission[] = [];

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {

  ReviewerGroup: any;
  SPINNER_MESSAGE
  photoUrl;
  authProvider;
  userId;
  userName;
  registerEmailPassword = false;
  userWelcomeMessage = AppConfig.MESSAGE_REVIEWER_WELCOME;

  constructor(private formbuilder: FormBuilder,

    private firebaseAuth: AngularFireAuth,
    private modalService: NgbModal,
    private router: Router,
    private loginSession: LajeanshareApiService,
    private dataService: LajeansdataApiService,
    private fireStoreService: AngularFirestore,
    public ngxShowLoader: NgxSpinnerService) { }

  searchColumns: string[] = ['lastName', 'firstName', 'email', 'submissionDate', 'open'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) table: MatTable<any>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  _userReviewSearchResults: any;



  ngOnInit(): void {
    this.ReviewerGroup = this.formbuilder.group({
      AuthorizationToken: [''],
      Id: [''],
      Provider: [''],
      UserName: [''],
      PhotoUrl: [''],
      ReviewerLastName: ['', Validators.required],
      ReviewerFirstName: ['', Validators.required],
      ReviewerMiddleName: [''],
      ReviewerSuffix: [''],
      ReviewerEmail: ['', Validators.compose([Validators.required, Validators.email])],
      ReviewerPhone: ['', Validators.required],

      ReviewerComments: ['', Validators.required]
    })

    this.getSocialProfileInfo();
    this.getUserReviews();
  }

  /**
 * Getter method to allow form to retrieve controls shorthand
 */
  get reviewerFormControl() {
    return this.ReviewerGroup.controls;
  }

  getUserRecord(recordId: any)
  {
    this.loginSession.setCommentId(recordId.id);
    const confirmSubmissionReviewModalRef = this.modalService.open(SubmissionReviewModalComponent, { size: 'lg', backdrop: 'static' })
    
    confirmSubmissionReviewModalRef.componentInstance.modal_header_title = AppConfig.MODAL_HEADER_PROFILE_UPDATE_TITLE_SUBMISSION_REVIEW;
    confirmSubmissionReviewModalRef.result.then((result) => {
     
    })
    
  }


  getUserReviews() {
    this.dataService.getReviewsByUserId().subscribe((responseData: {}) => {
      let response: any = responseData;
      const format = 'MM/dd/yyyy';
      const locale = 'en-US';

      if (response.size > 0) {
        ELEMENT_DATA = [];
        this._userReviewSearchResults = response.docs;

        this._userReviewSearchResults.forEach(element => {
          const formattedDate = formatDate(element.data().submissionDate, format, locale);
          let commentid: String = uuid.v4;
          let user: IReviewSubmission = {
            id: element.data().id,
            profile_id: "",
            email: element.data().email,
            firstName: element.data().firstName,
            lastName: element.data().lastName,
            phone: element.data().phone,
            comment: element.data().comments,
            commentId: commentid,
            submissionDate: formattedDate
          }
          console.log(element);
          ELEMENT_DATA.push(user);

        });

        this.updateUserListView();

      }

    })
  }

  updateUserListView() {
    this.dataSource = new MatTableDataSource<IReviewSubmission>(ELEMENT_DATA);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  /**
   * Fills registration fields
   */
  getSocialProfileInfo() {
    let userProfile: any = this.loginSession.getUserProfileData();

    if (userProfile != null) {
      this.ReviewerGroup.controls.ReviewerLastName.patchValue(userProfile.lastName);
      this.ReviewerGroup.controls.ReviewerFirstName.patchValue(userProfile.firstName);
      this.ReviewerGroup.controls.UserName.patchValue(userProfile.name);
      this.ReviewerGroup.controls.PhotoUrl.patchValue(userProfile.photoUrl);
      this.ReviewerGroup.controls.Provider.patchValue(userProfile.provider);
      this.ReviewerGroup.controls.Id.patchValue(userProfile.id);
      this.ReviewerGroup.controls.ReviewerEmail.patchValue(userProfile.email);
      this.ReviewerGroup.controls.AuthorizationToken.patchValue(userProfile.authToken);
      this.photoUrl = userProfile.photoUrl;
      this.authProvider = userProfile.provider;
      this.userId = userProfile.id;
      this.userName = userProfile.name;
    }
    else {
      this.showProfileError();

    }
  }


  /**
   * Method builds User object
   */
  saveProfile() {
    this.SPINNER_MESSAGE = AppConfig.SPINNER_UPDATE_SUBMIT_REVIEW;
    this.ngxShowLoader.show();

    if (this.ReviewerGroup.valid) {

      let reviewerSubmission: Reviewer = {
        id: this.ReviewerGroup.controls.Id.value,
        firstName: this.ReviewerGroup.controls.ReviewerFirstName.value,
        lastName: this.ReviewerGroup.controls.ReviewerLastName.value,
        middleName: this.ReviewerGroup.controls.ReviewerMiddleName.value,
        email: this.ReviewerGroup.controls.ReviewerEmail.value,
        phone: this.ReviewerGroup.controls.ReviewerPhone.value,
        provider: this.ReviewerGroup.controls.Provider.value,
        userName: this.ReviewerGroup.controls.UserName.value,
        submissionDate: new Date().toISOString(),
        comments: this.ReviewerGroup.controls.ReviewerComments.value
      }

      let result = this.dataService.saveReviewsDb(reviewerSubmission).then((result) => {
        this.ngxShowLoader.hide()
        const confirmSubmissionModalRef = this.modalService.open(SubmissionStatusModalComponent, { size: 'lg', backdrop: 'static' })

        confirmSubmissionModalRef.componentInstance.enableGotoProfile = true;
        confirmSubmissionModalRef.componentInstance.enableHome = false;
        confirmSubmissionModalRef.componentInstance.enableContinue = true;
        confirmSubmissionModalRef.componentInstance.modal_body = AppConfig.MODAL_BODY_REVIEW_SUBMISSION_SUCCESS;
        confirmSubmissionModalRef.componentInstance.modal_header_title = AppConfig.MODAL_HEADER_SUBMISSION_TITLE_SUCCESSFUL;
        confirmSubmissionModalRef.result.then((result) => {
          if (result != null) {

            this.loginSession.setCurrentUserProfileData(reviewerSubmission);
            if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_OPEN) {
              this.router.navigate([AppConfig.ROUTE_URL_MYINFO]);
            }
            else if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_HOME) {
              this.router.navigate([AppConfig.ROUTE_URL_HOME]);

            }
          }
        })
      }).catch((error) => {
        console.log(error.message);
        this.ngxShowLoader.hide();

      })
    }
    else {
      this.ngxShowLoader.hide();
      this.ReviewerGroup.markAllAsTouched();
    }
  }

  cancelReviewSubmission(){
    this.router.navigate([AppConfig.ROUTE_URL_HOME]);
  }

  /**
 * Displays there was a issue load profile and sends back to login screen
 */
  showProfileError() {
    this.ngxShowLoader.hide();
    const confirmSubmissionModalRef = this.modalService.open(SubmissionStatusModalComponent, { size: 'lg', backdrop: 'static' })
    confirmSubmissionModalRef.componentInstance.modal_body = AppConfig.MODAL_PROFILE_LOAD_ERROR;
    confirmSubmissionModalRef.componentInstance.enableHome = false;
    confirmSubmissionModalRef.componentInstance.modal_header_title = AppConfig.MODAL_PROFILE_LOAD_TITLE_ERROR;
    confirmSubmissionModalRef.result.then((result) => {
      if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_OPEN) {
        this.router.navigate([AppConfig.ROUTE_URL_MYINFO]);
      }
      else if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_HOME) {
        this.router.navigate([AppConfig.ROUTE_URL_HOME]);
      }
      else if (result.status == AppConfig.MODAL_ROUTE_DIRECTION_CONTINUE) {
        //Do nothing
      }
    })
  }



}
