<div class="container">
    <div class="row">
        <div class="col-12">
            <h1>What Does Name &quot;Lajean&quot; Mean</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            You always bring to completion anything you start.
            You are generous but like to see returns from your
            giving. You have an executive ability, you are a
            leader. You are moral, balanced, honest and
            intellectual, and you may attain spirituality. You are
            wise and practical with an appreciation of beauty.
            You always think before you act. You are bold,
            independent, inquisitive and interested in research.
            You know what you want and why you want it.
            You are very intuitive. You have a reservoir of
            inspired wisdom combined with inherited analytical
            ability, which could reward you through expressions
            of spiritual leadership, business analysis, marketing,
            artistic visions, and scientific research. Operating on
            spiritual side of your individuality can bring you to
            the great heights, and drop you off if you neglect
            your spiritual identity. You are always looking for an
            opportunity to investigate the unknown, to use and
            show your mental abilities, to find the purpose and
            meaning of life. You want to grow wise and to
            understand people and things. You need privacy to
            replenish your energy. You have a unique way of
            thinking, intuitive, reflective, absorbing.
        </div>
    </div>
    <br/><br/>
    <div class="row">
        <div class="col-12">
            <h1>About Our Staff</h1>
        </div>
    </div>
    
    <div class="card border-0 shadow p-3">
    <div class="row">
        <div class="col-4">
            <div class="card border-0 shadow">
                
                <div class="card-body">
                    <img src="../../../assets/img/dewan_320_400.jpg">
                </div>
                <div class="card-header text-center text-muted">
                    Dewan Smith-Williams
                  </div>
            </div>
        </div>
        <div class="col-8">
            <div class="card border-0 shadow">
                <div class="card-body p-5">
                    Dewan E Smith-Williams Psychiatric Nurse Practitioner, MSN, PMHAPRN, CNP-BC.  Lajeans will educate and provide awareness to the community to improve mental health outcomes
                    crossing all age groups. Lajeans will work to provide a safe, comfortable, nonjudgmental environment
                    that will enhance mental health treatment needed by many. Historically, mental illness was not
                    addressed as people were afraid or ashamed of the stigma surrounding mental illness. Lajeans will
                    provide treatment in a HIPPA compliant environment. Our goal is to expand our mission and values in
                    the community through clients sharing and advising others of the excellent service received and
                    referring friends, family and others <br/><br/>
                    When one goes to a boutique to purchase an eclectic piece, they expect to purchase a unique, one of
                    a kind piece. They do not want to see their purchase on someone else. Similarly, Lajeans Mental
                    Health Boutique will provided services based specifically on an individuals, physical, emotional and
                    spiritual needs. Treatment will be unique to each individual.<br/><br/>
                    Lajeans Mental Health Boutique will provide mental health treatment in a holistic manner. Treatment
                    will be planned and administered on an individual basis. Through experience, Lajeans knows when one
                    receives healthcare of any type they want to be listened to and heard. Lajeans will listen to your
                    concerns and address your symptoms.
                </div>
            </div>
        </div>

    </div>

    <div class="row">
        <div class="col-4">
            <div class="card border-0 shadow my-5">
                <div class="card-body">
                    <img src="../../../assets/img/sylvia.jpg">
                </div>
                <div class="card-header text-center text-muted">
                    Sylvia H Ruff
                  </div>
            </div>
        </div>
        <div class="col-8">
            <div class="card border-0 shadow my-5">
                <div class="card-body p-5">
                    Sylvia H Ruff Ed. D, MSN RN B.C., is a mental health nurse and educator of 38 years. As Lajeans Director of Addictive services, she focuses on increasing patient’s awareness of their need for mental health wellness, insight into their need for self-education, and coping skills along
                    with ability to be a productive individual while focusing on how to maintain their overall health.
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                </div>
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-4">
            <div class="card border-0 shadow">
                <div class="card-body">
                    <img src="../../../assets/img/robert.jpg">
                </div>
                <div class="card-header text-center text-muted">
                    Robert J. Toth
                  </div>
            </div>
        </div>
        <div class="col-8">
            <div class="card border-0 shadow">
                <div class="card-body p-5">
                    I am a Pharmacist at LaJean's Mental Health Boutique.  My responsibilities include: medication consultation, Med reviews, drug interaction assessments; performing prior authorizations and other duties as assigned. 
                    <br/><br/>

                    I am a graduate of Baldwin Wallace University and The Ohio State University.  I have a strong interest in mental health and I am working on becoming a board-certified psychiatric pharmacist.  In my free time I enjoy music, reading, biking, kayaking and the outdoors. 
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    
                     
                </div>
            </div>
        </div>

    </div>

    <!-- -->
    <br/>
    
</div>
</div>