<div class="container">
    <div class="row">
        <div class="col-12">
            <h1>Addiction Treatments</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <div class="card border-0 shadow my-5 clear-card-image">
                <div class="card-body">
                    <img src="../../../assets/img/vivitrol-logo.png">
                </div>
            </div>
        </div>
        <div class="col-8">
            <div class="card border-0 shadow my-5 clear-card">
                <div class="card-body p-5">
                    VIVITROL® (naltrexone for extended-release injectable suspension) is a once-monthly treatment proven
                    to help reduce heavy drinking days in alcohol-dependent patients when used with counseling.1,2
                    <br /><br />
                    VIVITROL and counseling help reinforce recovery for one month at a time while you work on the
                    psychological aspects of addiction through counseling.1
                    <br /><br />
                    Before starting VIVITROL, you must be opioid-free for a minimum of 7–14 days to avoid sudden opioid
                    withdrawal.
                    <br /><br />
                    For more information and to locate Lajeans Vivitrol treatment center click <a target="_blank"
                        href="https://www.vivitrol.com/alcohol-dependence/find-a-treatment-provider">here </a>
                </div>
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-4">
            <div class="card border-0 shadow my-5 clear-card-image">
                <div class="card-body">
                    <img src="../../../assets/img/sublocade.png">
                </div>
            </div>
        </div>
        <div class="col-8">
            <div class="card border-0 shadow my-5 clear-card">
                <div class="card-body p-5">
                    Sublocade (buprenorphine) is an opioid medication, sometimes called a narcotic.
                    <br /><br />
                    Sublocade is used to treat opioid addiction. It is not for use as a pain medication.
                    <br /><br />
                    Sublocade is given to people who have started addiction treatment with an oral form of buprenorphine
                    placed under the tongue or inside the cheek (such as Suboxone or Subutex).
                    <br /><br />
                    For more information and to locate Lajeans Sublocade treatment center click <a target="_blank"
                        href="https://www.sublocade.com/buprenorphine-treatment-provider/results?zipcode=44146">here
                    </a>
                </div>
            </div>
        </div>

    </div>

    <div class="row">
        <div class="col-4">
            <div class="card border-0 shadow my-5 clear-card-image">
                <div class="card-body">
                    <img src="../../../assets/img/suboxone.png">
                </div>
            </div>
        </div>
        <div class="col-8">
            <div class="card border-0 shadow my-5 clear-card">
                <div class="card-body p-5">
                    Suboxone contains a combination of buprenorphine and naloxone. Buprenorphine is an opioid
                    medication, sometimes called a narcotic. Naloxone blocks the effects of opioid medication, including
                    pain relief or feelings of well-being that can lead to opioid abuse.
                    <br /><br />

                    Suboxone is used to treat narcotic (opiate) addiction.
                    <br /><br />

                    Suboxone is not for use as a pain medication.
                    
                </div>
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-4">
            <div class="card border-0 shadow my-5 clear-card-image">
                <div class="card-body">
                    <img src="../../../assets/img/Subutex.png">
                </div>
            </div>
        </div>
        <div class="col-8">
            <div class="card border-0 shadow my-5 clear-card">
                <div class="card-body p-5">
                    Buprenorphine is used to treat dependence/addiction to opioids. Buprenorphine belongs to a class of drugs called mixed opioid agonist-antagonists. 
                    <br /><br />
 
                    It helps prevent withdrawal symptoms caused by stopping other opioids. It is used as part of a complete treatment program for drug abuse (such as compliance monitoring, counseling, behavioral contract, lifestyle changes).
                </div>
            </div>
        </div>

    </div>
</div>