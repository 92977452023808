<!-- Navigation -->
<nav class="navbar navbar-expand-lg navbar-light bg-light static-top mb-5 shadow">
    <div class="container">
      <a class="navbar-brand" href="#">Lajeans Mental Health Boutique</a>
      <!-- Professional verification provided by Psychology Today -->
<a href="https://www.psychologytoday.com/profile/726768" class="sx-verified-seal"><div class='verified-icon-xs small'>
  Verified by Psychology Today
</div></a>

<!-- End Verification -->
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item active">
            <a class="nav-link" href="">Home
                  <span class="sr-only">(current)</span>
                </a>
          </li>
          <li class="nav-item">
            <a routerLink="/about" class="nav-link" href="">About</a>
          </li>
          <li class="nav-item">
            <a routerLink="/services" class="nav-link" href="#">Services</a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link"(click)="openClientPortal()">{{BUTTON_TOOLBAR_CLIENTPORTAL}}</a>
          </li>
          <li class="nav-item">
            <a (click)="openLogin($event)" class="mr-1 btn btn-sm btn-outline-secondary">{{BUTTON_TOOLBAR_SUBMITREVIEW}}</a>
          </li>
          <li class="nav-item" [hidden]="!isLoggedIn">
            <a href="#" (click)="logout()" class="btn btn-sm btn-outline-secondary">{{BUTTON_TOOLBAR_LOGOUT}}</a>
          </li>
          <!--
          <li class="nav-item">
            <a href="#" routerLink="/myInfo" class="nav-link" href="#">{{BUTTON_TOOLBAR_MYINFO}}</a>
          </li>
          
         
          <li class="nav-item" [hidden]="!isAdmin">
            <a routerLink="/administration" class="nav-link" href="">{{BUTTON_TOOLBAR_ADMINISTRATION}}</a>
          </li>
          
          <li class="nav-item" [hidden]="isLoggedIn">
            <a (click)="openLogin($event)" class="btn btn-sm btn-outline-secondary">{{BUTTON_TOOLBAR_LOGIN}}</a>
          </li>
      
          -->
          <!--
          <li class="nav-item">
            &nbsp;&nbsp;<a href="#" class="btn btn-sm btn-primary">Get Started</a>
          </li>
          -->
          <li class="nav-item" [hidden]="!isLoggedIn">
           <a href="#" class="nav-link"><h4><b>{{username}}</b></h4></a>
          </li>

        </ul>
      </div>
    </div>
  </nav>