import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './component/about/about.component';
import { AddictionComponent } from './component/addiction/addiction.component';
import { AdministrationComponent } from './component/administration/administration.component';
import { EditUserComponent } from './component/administration/edit-user/edit-user.component';
import { AnxietyComponent } from './component/anxiety/anxiety.component';
import { NavigationComponent } from './component/navigation/navigation.component';
import { ReviewComponent } from './component/review/review.component';
import { ServicesComponent } from './component/services/services.component';
import { SpravatoComponent } from './component/spravato/spravato.component';
import { TransgenderComponent } from './component/transgender/transgender.component';
import { UserInfoComponent } from './component/user-info/user-info.component';
import { UserRegistrationComponent } from './component/user-registration/user-registration.component';
import { VivitrolComponent } from './component/vivitrol/vivitrol.component';

const routes: Routes = [
  { path: '', component: NavigationComponent },
  { path: 'client', component: NavigationComponent },
  { path: 'about', component: AboutComponent },
  { path: 'register', component: UserRegistrationComponent },
  { path: 'administration', component: AdministrationComponent },
  { path: 'spravato', component:  SpravatoComponent},
  { path: 'vivitrol', component:  VivitrolComponent},
  { path: 'anxiety', component:  AnxietyComponent},
  { path: 'addiction', component:  AddictionComponent},
  { path: 'transgender', component:  TransgenderComponent},
  { path: 'editUser', component: EditUserComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'review', component: ReviewComponent },
  { path: 'myInfo', component: UserInfoComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
