// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
firebase: {
  apiKey: "AIzaSyD-yhXwdyCpzSv4WqROhYxDO5pHyPFORbc",
  authDomain: "lajeansmhb-45516.firebaseapp.com",
  databaseURL: "https://lajeansmhb-45516.firebaseio.com",
  projectId: "lajeansmhb-45516",
  storageBucket: "lajeansmhb-45516.appspot.com",
  messagingSenderId: "940752720001",
  appId: "1:940752720001:web:1f2f722e27ebb738c4fd80",
  measurementId: "G-7GQ50WYTDH"
}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
