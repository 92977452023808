<form [formGroup]="AdministrationFormGroup">
    <div class="container">
        <div class="row clearfix">
            <div class="col-4">
                <div class="form-group">
                    <div class="input-group">
                        <input type="text" name="search" class="form-control" placeholder="Search By Email">
                        <span><button type="button" class="btn btn-primary"><i class="fa fa-search"></i></button></span>
                     </div>
                </div>
            </div>
        </div>
        <br />
        <div class="wrapper">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 user-profile-table" matSort>
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Social Profile Id</th>
                    <td mat-cell *matCellDef="let element">{{element.id}}</td>
                </ng-container>
                <ng-container matColumnDef="profile_id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Insurance Group Id</th>
                    <td mat-cell *matCellDef="let element">{{element.profile_id}}</td>
                </ng-container>

                <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
                    <td mat-cell *matCellDef="let element">{{element.lastName}}</td>
                </ng-container>
                <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
                    <td mat-cell *matCellDef="let element">{{element.firstName}}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Email Address</th>
                    <td mat-cell *matCellDef="let element">{{element.email}}</td>
                </ng-container>
                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone Number</th>
                    <td mat-cell *matCellDef="let element">{{element.phone}}</td>
                </ng-container>
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element" class="action-link">
                        <button type="button" class="btn btn-sm btn-success"
                            (click)="getUserRecord(element)">Edit</button>&nbsp;
                    </td>

                </ng-container>
                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element" class="action-link">
                        <button type="button" class="btn btn-sm btn-success"
                            (click)="getUserRecord(element)">Delete</button>&nbsp;
                    </td>

                </ng-container>

                <tr mat-header-row *matHeaderRowDef="searchColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: searchColumns"></tr>

            </table>
            
            <mat-paginator [pageSizeOptions]="[5,10,15]" showFirstLastButtons></mat-paginator>

        </div>
    </div>
</form>